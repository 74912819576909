@charset "UTF-8";
.zmz-button,
.zmz__nav {
  -webkit-appearance: none;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  box-sizing: border-box;
}
.zmz-button,
.zmz-row,
.zmz__nav {
  box-sizing: border-box;
}
.zmz-button,
.zmz-tag,
.zmz__nav {
  text-align: center;
  white-space: nowrap;
  outline: 0;
}
.zmz-button,
.zmz-pagination,
.zmz__nav {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.zmz-row:after,
.zmz-row:before {
  display: table;
  content: "";
}
.zmz-row:after {
  clear: both;
}
.zmz-button {
  display: inline-block;
  line-height: 1;
  cursor: pointer;
  background: #fff;
  border: 1px solid #d6d6d6;
  color: #737373;
  margin: 0;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 14px;
}
.zmz-button.is-round {
  border-radius: 20px;
}
.zmz-button--size-medium {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
}
.zmz-button--size-small {
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 3px;
}
.zmz-button--default {
  background: #fff;
  border-color: #d6d6d6;
  color: #737373;
}
.zmz-button--default:focus,
.zmz-button--default:hover {
  background: #fff;
  border-color: #c3c3c3;
  color: #828282;
}
.zmz-button--default.is-disabled.is-active.is-round:disabled,
.zmz-button--default.is-disabled.is-active:disabled,
.zmz-button--default.is-disabled.is-plain.is-active:disabled,
.zmz-button--default.is-disabled.is-plain.is-round:disabled,
.zmz-button--default.is-disabled.is-round.is-plain.is-active:disabled,
.zmz-button--default.is-disabled.is-round:disabled,
.zmz-button--default.is-disabled:disabled {
  cursor: not-allowed;
  color: #d6d6d6;
  background: #fff;
  border-color: #d6d6d6;
}
.zmz-button--default.is-active:active,
.zmz-button--default.is-round:active {
  background: #f9f9f9;
  border-color: #dedede;
  color: #8e8e8e;
}
.zmz-button--default.is-plain,
.zmz-button--default.is-plain:focus,
.zmz-button--default.is-plain:hover {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(214, 214, 214, 0.8);
  color: #737373;
}
.zmz-button--default.is-plain:active {
  background: #f9f9f9;
  border-color: #dedede;
  color: #8e8e8e;
}
.zmz-button--default.is-plain.is-disabled:disabled {
  cursor: not-allowed;
  background: #fff;
  border-color: #e6e6e6;
  color: #e6e6e6;
}
.zmz-button--success {
  background: #2eca7b;
  border-color: #2eca7b;
  color: #f1f1f1;
}
.zmz-button--success:focus,
.zmz-button--success:hover {
  background: #4edc94;
  border-color: #4edc94;
  color: #fff;
}
.zmz-button--success.is-active:active,
.zmz-button--success.is-round:active {
  background: #1cb366;
  border-color: #1cb366;
  color: #fff;
}
.zmz-button--success.is-disabled.is-active.is-round:disabled,
.zmz-button--success.is-disabled.is-active:disabled,
.zmz-button--success.is-disabled.is-plain.is-active:disabled,
.zmz-button--success.is-disabled.is-plain.is-round:disabled,
.zmz-button--success.is-disabled.is-round.is-plain.is-active:disabled,
.zmz-button--success.is-disabled:disabled {
  cursor: not-allowed;
  background: #81e0b0;
  border-color: #81e0b0;
  color: #fff;
}
.zmz-button--success.is-plain {
  background: rgba(46, 202, 123, 0.05);
  border-color: rgba(46, 202, 123, 0.8);
  color: #2eca7b;
}
.zmz-button--success.is-plain:focus,
.zmz-button--success.is-plain:hover {
  background: #2eca7b;
  border: 1px solid #2eca7b;
  color: #f1f1f1;
}
.zmz-button--success.is-plain.is-active:active {
  background: #1cb366;
  border-color: #1cb366;
  color: #fff;
}
.zmz-button--success.is-plain.is-disabled:disabled {
  cursor: not-allowed;
  background: #f4fcf8;
  border-color: #abf7d1;
  color: #88e2b4;
}
.zmz-button--confirm {
  background: #53a2f3;
  border-color: #53a2f3;
  color: #f1f1f1;
}
.zmz-button--confirm:focus,
.zmz-button--confirm:hover {
  background: #6eacea;
  border-color: #6eacea;
  color: #fff;
}
.zmz-button--confirm.is-active:active,
.zmz-button--confirm.is-round:active {
  background: #3a8ee4;
  border-color: #3a8ee4;
  color: #fff;
}
.zmz-button--confirm.is-disabled.is-active.is-round:disabled,
.zmz-button--confirm.is-disabled.is-plain.is-active:disabled,
.zmz-button--confirm.is-disabled.is-plain.is-round:disabled,
.zmz-button--confirm.is-disabled.is-round.is-plain.is-active:disabled,
.zmz-button--confirm.is-disabled:disabled {
  cursor: not-allowed;
  color: #fff;
  background: #b3d7fd;
  border-color: #b3d7fd;
}
.zmz-button--confirm.is-plain {
  background: rgba(83, 162, 243, 0.05);
  border-color: rgba(83, 162, 243, 0.8);
  color: #53a2f3;
}
.zmz-button--confirm.is-plain:focus,
.zmz-button--confirm.is-plain:hover {
  background: #6eacea;
  border-color: #6eacea;
  color: #f1f1f1;
}
.zmz-button--confirm.is-plain:active {
  background: #3a8ee4;
  border-color: #3a8ee4;
  color: #f1f1f1;
}
.zmz-button--confirm.is-plain.is-disabled:disabled {
  cursor: not-allowed;
  background: #f6fafe;
  border-color: #c6e0fb;
  color: #c6e0fb;
}
.zmz-button--warning {
  background: #d89358;
  border-color: #d89358;
  color: #f1f1f1;
}
.zmz-button--warning:focus,
.zmz-button--warning:hover {
  background: #e4a56e;
  border-color: #e4a56e;
  color: #fff;
}
.zmz-button--warning.is-active:active,
.zmz-button--warning.is-round:active {
  background: #ca8245;
  border-color: #ca8245;
  color: #fff;
}
.zmz-button--warning.is-disabled.is-active.is-round:disabled,
.zmz-button--warning.is-disabled.is-active:disabled,
.zmz-button--warning.is-disabled.is-plain.is-active:disabled,
.zmz-button--warning.is-disabled.is-plain.is-round:disabled,
.zmz-button--warning.is-disabled.is-round.is-plain.is-active:disabled,
.zmz-button--warning.is-disabled:disabled {
  cursor: not-allowed;
  color: #f1f1f1;
  background: #f1c39c;
  border-color: #f1c39c;
}
.zmz-button--warning.is-plain {
  background: rgba(216, 147, 88, 0.05);
  border-color: rgba(216, 147, 88, 0.8);
  color: #d89358;
}
.zmz-button--warning.is-plain:focus,
.zmz-button--warning.is-plain:hover {
  background: #d89358;
  border-color: #d89358;
  color: #f1f1f1;
}
.zmz-button--warning.is-plain:active {
  background: #ca8245;
  border-color: #ca8245;
  color: #fff;
}
.zmz-button--warning.is-plain.is-disabled:disabled {
  cursor: not-allowed;
  background: #fdf9f6;
  border-color: #fdcfa8;
  color: #fdcfa8;
}
.zmz-button--error {
  background: #f15c5c;
  border-color: #f15c5c;
  color: #f1f1f1;
}
.zmz-button--error:focus,
.zmz-button--error:hover {
  background: #f48282;
  border-color: #f48282;
  color: #fff;
}
.zmz-button--error.is-active:active,
.zmz-button--error.is-round:active {
  background: #d85656;
  border-color: #d85656;
  color: #fff;
}
.zmz-button--error.is-disabled.is-active.is-round:disabled,
.zmz-button--error.is-disabled.is-active:disabled,
.zmz-button--error.is-disabled.is-plain.is-active:disabled,
.zmz-button--error.is-disabled.is-plain.is-round:disabled,
.zmz-button--error.is-disabled.is-plain:disabled,
.zmz-button--error.is-disabled.is-round.is-plain.is-active:disabled,
.zmz-button--error.is-disabled.is-round:disabled,
.zmz-button--error.is-disabled:disabled {
  cursor: not-allowed;
  color: #f1f1f1;
  background: #f1b5b5;
  border-color: #f1b5b5;
}
.zmz-button--error.is-plain {
  background: rgba(241, 92, 92, 0.05);
  border-color: rgba(241, 92, 92, 0.8);
  color: #f15c5c;
}
.zmz-button--error.is-plain:focus,
.zmz-button--error.is-plain:hover {
  background: #f15c5c;
  border-color: #f15c5c;
  color: #f1f1f1;
}
.zmz-button--error.is-plain:active {
  background: #d85656;
  border-color: #d85656;
  color: #fff;
}
.zmz-button--error.is-plain.is-disabled:disabled {
  cursor: not-allowed;
  background: #fef7f7;
  border-color: #f7dede;
  color: #f9b0b0;
}
.zmz-button--info {
  background: #a0a0a0;
  border-color: #a0a0a0;
  color: #fff;
}
.zmz-button--info:focus,
.zmz-button--info:hover {
  background: #ababab;
  border-color: #ababab;
  color: #fff;
}
.zmz-button--info.is-disabled.is-active.is-round:disabled,
.zmz-button--info.is-disabled.is-active:disabled,
.zmz-button--info.is-disabled.is-plain.is-active:disabled,
.zmz-button--info.is-disabled.is-plain.is-round:disabled,
.zmz-button--info.is-disabled.is-round.is-plain.is-active:disabled,
.zmz-button--info.is-disabled.is-round:disabled,
.zmz-button--info.is-disabled:disabled {
  cursor: not-allowed;
  background: #f1f1f1;
  border-color: #f1f1f1;
  color: #ccc;
}
.zmz-button--info.is-active:active,
.zmz-button--info.is-round:active {
  background: #a0a0a0;
  border-color: #a0a0a0;
  color: #fff;
}
.zmz-button--info.is-plain {
  background: rgba(160, 160, 160, 0.05);
  border-color: rgba(160, 160, 160, 0.8);
  color: #a0a0a0;
}
.zmz-button--info.is-plain:active,
.zmz-button--info.is-plain:focus,
.zmz-button--info.is-plain:hover {
  background: #a0a0a0;
  border-color: #a0a0a0;
  color: #fff;
}
.zmz-button--info.is-plain.is-disabled:disabled {
  cursor: not-allowed;
  background: #fafafa;
  border-color: #f1f1f1;
  color: #d2d2d2;
}
.zmz-menu--horizontal {
  border-bottom: solid 1px #e6e6e6;
  list-style: none;
  position: relative;
  margin: 0;
  padding-left: 0;
  background-color: #fff;
}
.zmz-menu--horizontal .zmz-menu-item {
  position: relative;
  color: #6e8aaf;
  float: left;
  padding: 0 25px;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
}
.zmz-menu--horizontal .zmz-menu-item .zmz-submenu_right {
  left: 210px;
  top: 0;
}
.zmz-menu--horizontal .zmz-menu-item .zmz-submenu_left {
  left: -210px;
  top: 0;
}
.zmz-menu--horizontal .zmz-menu-item .zmz-submenu_bottom {
  left: 0;
}
.zmz-menu--horizontal .zmz-menu-item.is-disabled {
  cursor: not-allowed;
  color: #828282;
  background: 0 0 !important;
}
.zmz-menu--horizontal .zmz-menu-item:focus,
.zmz-menu--horizontal .zmz-menu-item:hover {
  outline: 0;
}
.zmz-menu--horizontal .zmz-submenu .zmz-menu-item {
  display: block;
  height: 40px;
  padding: 0 10px;
  line-height: 40px;
  float: none;
}
.zmz-menu--horizontal .zmz-submenu .zmz-menu-group .zmz-menu-group_title {
  color: #d0d0d0;
  font-size: 14px;
  padding: 10px;
  line-height: 1;
}
.zmz-menu--horizontal .zmz-submenu ::after {
  clear: both;
  display: block;
  overflow: hidden;
  content: '';
}
.zmz-menu--horizontal > .zmz-submenu > .submenu-content {
  margin-top: 10px;
}
.zmz-menu--horizontal .zmz-submenu {
  background: #fff;
  left: 0;
  box-shadow: 0 10px 60px 0 rgba(29, 29, 31, 0.07);
  position: relative;
  color: #6e8aaf;
  float: left;
  cursor: pointer;
}
.zmz-menu--horizontal .zmz-submenu .submenu-content .zmz-submenu,
.zmz-menu--vertical .zmz-submenu .submenu-content .zmz-submenu,
.zmz-menu--vertical .zmz-submenu .zmz-menu-item {
  float: none;
  display: block;
}
.zmz-menu--horizontal .zmz-submenu .submenu-title {
  padding: 0 25px;
  outline: 0;
  height: 60px;
  line-height: 60px;
}
.zmz-menu--horizontal .zmz-submenu .submenu-title:focus,
.zmz-menu--horizontal .zmz-submenu .submenu-title:hover {
  outline: 0;
}
.zmz-menu--horizontal .zmz-submenu .submenu-content {
  min-width: 200px;
  position: absolute;
  left: 0;
  border-radius: 3px;
  padding: 5px 0;
}
.zmz-col-pull-0,
.zmz-col-pull-1,
.zmz-col-pull-10,
.zmz-col-pull-12,
.zmz-col-pull-13,
.zmz-col-pull-14,
.zmz-col-pull-15,
.zmz-col-pull-16,
.zmz-col-pull-17,
.zmz-col-pull-18,
.zmz-col-pull-19,
.zmz-col-pull-2,
.zmz-col-pull-20,
.zmz-col-pull-21,
.zmz-col-pull-22,
.zmz-col-pull-23,
.zmz-col-pull-24,
.zmz-col-pull-3,
.zmz-col-pull-4,
.zmz-col-pull-5,
.zmz-col-pull-6,
.zmz-col-pull-7,
.zmz-col-pull-8,
.zmz-col-pull-9,
.zmz-col-push-0,
.zmz-col-push-1,
.zmz-col-push-10,
.zmz-col-push-11,
.zmz-col-push-12,
.zmz-col-push-13,
.zmz-col-push-14,
.zmz-col-push-15,
.zmz-col-push-16,
.zmz-col-push-17,
.zmz-col-push-18,
.zmz-col-push-19,
.zmz-col-push-2,
.zmz-col-push-20,
.zmz-col-push-21,
.zmz-col-push-22,
.zmz-col-push-23,
.zmz-col-push-24,
.zmz-col-push-3,
.zmz-col-push-4,
.zmz-col-push-5,
.zmz-col-push-6,
.zmz-col-push-7,
.zmz-col-push-8,
.zmz-col-push-9,
.zmz-menu--vertical,
.zmz-menu--vertical .zmz-menu-item,
.zmz-menu--vertical .zmz-submenu,
.zmz-row {
  position: relative;
}
.zmz-menu--horizontal .zmz-submenu .submenu-content .submenu-title {
  padding: 0 10px;
  outline: 0;
  height: 40px;
  line-height: 40px;
}
.zmz-menu--horizontal .zmz-submenu .submenu-content .submenu-left {
  left: -210px;
  top: 0;
}
.zmz-menu--horizontal .zmz-submenu .submenu-content .submenu-bottom {
  left: 0;
}
.zmz-menu--horizontal .zmz-submenu .submenu-content .submenu-right {
  left: 210px;
  top: 0;
}
.zmz-menu--horizontal .zmz-submenu:focus,
.zmz-menu--horizontal .zmz-submenu:hover {
  outline: 0;
}
.zmz-menu--horizontal::after {
  content: "";
  clear: both;
  overflow: hidden;
  display: block;
}
.zmz-menu--vertical {
  list-style: none;
  margin: 0;
  padding-left: 0;
  background-color: #fff;
  border-bottom: 0;
}
.zmz-menu--vertical .zmz-menu-item {
  color: #6e8aaf;
  display: block;
  padding: 0 25px;
  cursor: pointer;
  height: 60px;
  line-height: 60px;
}
.zmz-menu--vertical .zmz-menu-item.is-disabled {
  cursor: not-allowed;
  color: #828282;
  background: 0 0 !important;
}
.zmz-demo--1__login .zmz-login__body .zmz-login__disabled p,
.zmz__nav {
  cursor: pointer;
}
.zmz-menu--vertical .zmz-menu-item:focus,
.zmz-menu--vertical .zmz-menu-item:hover {
  outline: 0;
}
.zmz-menu--vertical .zmz-submenu {
  background: #fff;
  left: 0;
  box-shadow: 0 10px 60px 0 rgba(29, 29, 31, 0.07);
  color: #6e8aaf;
  display: block;
  cursor: pointer;
}
.zmz-menu--vertical .zmz-submenu .submenu-title {
  padding: 0 25px;
  height: 60px;
  line-height: 60px;
  outline: 0;
}
.zmz-menu--vertical .zmz-submenu .submenu-title:focus,
.zmz-menu--vertical .zmz-submenu .submenu-title:hover {
  outline: 0;
}
.zmz-menu--vertical .zmz-submenu .submenu-content .submenu-title {
  padding: 0 10px;
  line-height: 40px;
  height: 40px;
  outline: 0;
}
.zmz-menu--vertical .zmz-submenu .submenu-content .zmz-menu-item {
  display: block;
  padding: 0 10px;
  line-height: 40px;
  height: 40px;
  float: none;
}
.zmz-menu--vertical .zmz-submenu .zmz-menu-item {
  padding: 0 10px;
}
.zmz-menu--vertical .zmz-submenu .zmz-menu-group .zmz-menu-group_title {
  color: #d0d0d0;
  font-size: 14px;
  padding: 10px;
  line-height: 1;
}
.zmz-menu--vertical .zmz-submenu ::after {
  clear: both;
  display: block;
  overflow: hidden;
  content: '';
}
.zmz-menu--vertical .zmz-submenu:focus,
.zmz-menu--vertical .zmz-submenu:hover {
  outline: 0;
}
.zmz-menu--vertical::after {
  content: "";
  clear: both;
  overflow: hidden;
  display: block;
}
.zmz-tag,
.zmz__nav {
  display: inline-block;
}
.zmz__nav {
  line-height: 1;
  background: #fff;
  color: #8a8a8a;
  margin: 0;
  font-size: 14px;
}
.zmz__nav.is-round {
  border-radius: 20px;
}
.zmz__nav--horizontal ul li {
  color: #000;
  float: left;
  padding: 12px 20px;
}
.zmz__nav--size-small {
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 3px;
}
.zmz-col-1,
.zmz-col-10,
.zmz-col-11,
.zmz-col-12,
.zmz-col-13,
.zmz-col-14,
.zmz-col-15,
.zmz-col-16,
.zmz-col-17,
.zmz-col-18,
.zmz-col-19,
.zmz-col-2,
.zmz-col-20,
.zmz-col-21,
.zmz-col-22,
.zmz-col-23,
.zmz-col-24,
.zmz-col-3,
.zmz-col-4,
.zmz-col-5,
.zmz-col-6,
.zmz-col-7,
.zmz-col-8,
.zmz-col-9 {
  float: left;
  box-sizing: border-box;
}
.zmz-col-0 {
  width: 0%;
}
.zmz-col-offset-0 {
  margin-left: 0;
}
.zmz-col-pull-0 {
  right: 0;
}
.zmz-col-push-0 {
  left: 0;
}
.zmz-col-1 {
  width: 4.16667%;
}
.zmz-col-offset-1 {
  margin-left: 4.16667%;
}
.zmz-col-pull-1 {
  right: 4.16667%;
}
.zmz-col-push-1 {
  left: 4.16667%;
}
.zmz-col-2 {
  width: 8.33333%;
}
.zmz-col-offset-2 {
  margin-left: 8.33333%;
}
.zmz-col-pull-2 {
  right: 8.33333%;
}
.zmz-col-push-2 {
  left: 8.33333%;
}
.zmz-col-3 {
  width: 12.5%;
}
.zmz-col-offset-3 {
  margin-left: 12.5%;
}
.zmz-col-pull-3 {
  right: 12.5%;
}
.zmz-col-push-3 {
  left: 12.5%;
}
.zmz-col-4 {
  width: 16.66667%;
}
.zmz-col-offset-4 {
  margin-left: 16.66667%;
}
.zmz-col-pull-4 {
  right: 16.66667%;
}
.zmz-col-push-4 {
  left: 16.66667%;
}
.zmz-col-5 {
  width: 20.83333%;
}
.zmz-col-offset-5 {
  margin-left: 20.83333%;
}
.zmz-col-pull-5 {
  right: 20.83333%;
}
.zmz-col-push-5 {
  left: 20.83333%;
}
.zmz-col-6 {
  width: 25%;
}
.zmz-col-offset-6 {
  margin-left: 25%;
}
.zmz-col-pull-6 {
  right: 25%;
}
.zmz-col-push-6 {
  left: 25%;
}
.zmz-col-7 {
  width: 29.16667%;
}
.zmz-col-offset-7 {
  margin-left: 29.16667%;
}
.zmz-col-pull-7 {
  right: 29.16667%;
}
.zmz-col-push-7 {
  left: 29.16667%;
}
.zmz-col-8 {
  width: 33.33333%;
}
.zmz-col-offset-8 {
  margin-left: 33.33333%;
}
.zmz-col-pull-8 {
  right: 33.33333%;
}
.zmz-col-push-8 {
  left: 33.33333%;
}
.zmz-col-9 {
  width: 37.5%;
}
.zmz-col-offset-9 {
  margin-left: 37.5%;
}
.zmz-col-pull-9 {
  right: 37.5%;
}
.zmz-col-push-9 {
  left: 37.5%;
}
.zmz-col-10 {
  width: 41.66667%;
}
.zmz-col-offset-10 {
  margin-left: 41.66667%;
}
.zmz-col-pull-10 {
  right: 41.66667%;
}
.zmz-col-push-10 {
  left: 41.66667%;
}
.zmz-col-11 {
  width: 45.83333%;
}
.zmz-col-offset-11 {
  margin-left: 45.83333%;
}
.zmz-col-pull-11 {
  position: relative;
  right: 45.83333%;
}
.zmz-col-push-11 {
  left: 45.83333%;
}
.zmz-col-12 {
  width: 50%;
}
.zmz-col-offset-12 {
  margin-left: 50%;
}
.zmz-col-pull-12 {
  right: 50%;
}
.zmz-col-push-12 {
  left: 50%;
}
.zmz-col-13 {
  width: 54.16667%;
}
.zmz-col-offset-13 {
  margin-left: 54.16667%;
}
.zmz-col-pull-13 {
  right: 54.16667%;
}
.zmz-col-push-13 {
  left: 54.16667%;
}
.zmz-col-14 {
  width: 58.33333%;
}
.zmz-col-offset-14 {
  margin-left: 58.33333%;
}
.zmz-col-pull-14 {
  right: 58.33333%;
}
.zmz-col-push-14 {
  left: 58.33333%;
}
.zmz-col-15 {
  width: 62.5%;
}
.zmz-col-offset-15 {
  margin-left: 62.5%;
}
.zmz-col-pull-15 {
  right: 62.5%;
}
.zmz-col-push-15 {
  left: 62.5%;
}
.zmz-col-16 {
  width: 66.66667%;
}
.zmz-col-offset-16 {
  margin-left: 66.66667%;
}
.zmz-col-pull-16 {
  right: 66.66667%;
}
.zmz-col-push-16 {
  left: 66.66667%;
}
.zmz-col-17 {
  width: 70.83333%;
}
.zmz-col-offset-17 {
  margin-left: 70.83333%;
}
.zmz-col-pull-17 {
  right: 70.83333%;
}
.zmz-col-push-17 {
  left: 70.83333%;
}
.zmz-col-18 {
  width: 75%;
}
.zmz-col-offset-18 {
  margin-left: 75%;
}
.zmz-col-pull-18 {
  right: 75%;
}
.zmz-col-push-18 {
  left: 75%;
}
.zmz-col-19 {
  width: 79.16667%;
}
.zmz-col-offset-19 {
  margin-left: 79.16667%;
}
.zmz-col-pull-19 {
  right: 79.16667%;
}
.zmz-col-push-19 {
  left: 79.16667%;
}
.zmz-col-20 {
  width: 83.33333%;
}
.zmz-col-offset-20 {
  margin-left: 83.33333%;
}
.zmz-col-pull-20 {
  right: 83.33333%;
}
.zmz-col-push-20 {
  left: 83.33333%;
}
.zmz-col-21 {
  width: 87.5%;
}
.zmz-col-offset-21 {
  margin-left: 87.5%;
}
.zmz-col-pull-21 {
  right: 87.5%;
}
.zmz-col-push-21 {
  left: 87.5%;
}
.zmz-col-22 {
  width: 91.66667%;
}
.zmz-col-offset-22 {
  margin-left: 91.66667%;
}
.zmz-col-pull-22 {
  right: 91.66667%;
}
.zmz-col-push-22 {
  left: 91.66667%;
}
.zmz-col-23 {
  width: 95.83333%;
}
.zmz-col-offset-23 {
  margin-left: 95.83333%;
}
.zmz-col-pull-23 {
  right: 95.83333%;
}
.zmz-col-push-23 {
  left: 95.83333%;
}
.zmz-col-24 {
  width: 100%;
}
.zmz-col-offset-24 {
  margin-left: 100%;
}
.zmz-col-pull-24 {
  right: 100%;
}
.zmz-col-push-24 {
  left: 100%;
}
@media (max-width: 768px) {
  .zmz-col-xs-0 {
    width: 0%;
  }
  .zmz-col-xs-offset-0 {
    margin-left: 0;
  }
  .zmz-col-xs-pull-0 {
    position: relative;
    right: 0;
  }
  .zmz-col-xs-push-0 {
    position: relative;
    left: 0;
  }
  .zmz-col-xs-1 {
    width: 4.16667%;
  }
  .zmz-col-xs-offset-1 {
    margin-left: 4.16667%;
  }
  .zmz-col-xs-pull-1 {
    position: relative;
    right: 4.16667%;
  }
  .zmz-col-xs-push-1 {
    position: relative;
    left: 4.16667%;
  }
  .zmz-col-xs-2 {
    width: 8.33333%;
  }
  .zmz-col-xs-offset-2 {
    margin-left: 8.33333%;
  }
  .zmz-col-xs-pull-2 {
    position: relative;
    right: 8.33333%;
  }
  .zmz-col-xs-push-2 {
    position: relative;
    left: 8.33333%;
  }
  .zmz-col-xs-3 {
    width: 12.5%;
  }
  .zmz-col-xs-offset-3 {
    margin-left: 12.5%;
  }
  .zmz-col-xs-pull-3 {
    position: relative;
    right: 12.5%;
  }
  .zmz-col-xs-push-3 {
    position: relative;
    left: 12.5%;
  }
  .zmz-col-xs-4 {
    width: 16.66667%;
  }
  .zmz-col-xs-offset-4 {
    margin-left: 16.66667%;
  }
  .zmz-col-xs-pull-4 {
    position: relative;
    right: 16.66667%;
  }
  .zmz-col-xs-push-4 {
    position: relative;
    left: 16.66667%;
  }
  .zmz-col-xs-5 {
    width: 20.83333%;
  }
  .zmz-col-xs-offset-5 {
    margin-left: 20.83333%;
  }
  .zmz-col-xs-pull-5 {
    position: relative;
    right: 20.83333%;
  }
  .zmz-col-xs-push-5 {
    position: relative;
    left: 20.83333%;
  }
  .zmz-col-xs-6 {
    width: 25%;
  }
  .zmz-col-xs-offset-6 {
    margin-left: 25%;
  }
  .zmz-col-xs-pull-6 {
    position: relative;
    right: 25%;
  }
  .zmz-col-xs-push-6 {
    position: relative;
    left: 25%;
  }
  .zmz-col-xs-7 {
    width: 29.16667%;
  }
  .zmz-col-xs-offset-7 {
    margin-left: 29.16667%;
  }
  .zmz-col-xs-pull-7 {
    position: relative;
    right: 29.16667%;
  }
  .zmz-col-xs-push-7 {
    position: relative;
    left: 29.16667%;
  }
  .zmz-col-xs-8 {
    width: 33.33333%;
  }
  .zmz-col-xs-offset-8 {
    margin-left: 33.33333%;
  }
  .zmz-col-xs-pull-8 {
    position: relative;
    right: 33.33333%;
  }
  .zmz-col-xs-push-8 {
    position: relative;
    left: 33.33333%;
  }
  .zmz-col-xs-9 {
    width: 37.5%;
  }
  .zmz-col-xs-offset-9 {
    margin-left: 37.5%;
  }
  .zmz-col-xs-pull-9 {
    position: relative;
    right: 37.5%;
  }
  .zmz-col-xs-push-9 {
    position: relative;
    left: 37.5%;
  }
  .zmz-col-xs-10 {
    width: 41.66667%;
  }
  .zmz-col-xs-offset-10 {
    margin-left: 41.66667%;
  }
  .zmz-col-xs-pull-10 {
    position: relative;
    right: 41.66667%;
  }
  .zmz-col-xs-push-10 {
    position: relative;
    left: 41.66667%;
  }
  .zmz-col-xs-11 {
    width: 45.83333%;
  }
  .zmz-col-xs-offset-11 {
    margin-left: 45.83333%;
  }
  .zmz-col-xs-pull-11 {
    position: relative;
    right: 45.83333%;
  }
  .zmz-col-xs-push-11 {
    position: relative;
    left: 45.83333%;
  }
  .zmz-col-xs-12 {
    width: 50%;
  }
  .zmz-col-xs-offset-12 {
    margin-left: 50%;
  }
  .zmz-col-xs-pull-12 {
    position: relative;
    right: 50%;
  }
  .zmz-col-xs-push-12 {
    position: relative;
    left: 50%;
  }
  .zmz-col-xs-13 {
    width: 54.16667%;
  }
  .zmz-col-xs-offset-13 {
    margin-left: 54.16667%;
  }
  .zmz-col-xs-pull-13 {
    position: relative;
    right: 54.16667%;
  }
  .zmz-col-xs-push-13 {
    position: relative;
    left: 54.16667%;
  }
  .zmz-col-xs-14 {
    width: 58.33333%;
  }
  .zmz-col-xs-offset-14 {
    margin-left: 58.33333%;
  }
  .zmz-col-xs-pull-14 {
    position: relative;
    right: 58.33333%;
  }
  .zmz-col-xs-push-14 {
    position: relative;
    left: 58.33333%;
  }
  .zmz-col-xs-15 {
    width: 62.5%;
  }
  .zmz-col-xs-offset-15 {
    margin-left: 62.5%;
  }
  .zmz-col-xs-pull-15 {
    position: relative;
    right: 62.5%;
  }
  .zmz-col-xs-push-15 {
    position: relative;
    left: 62.5%;
  }
  .zmz-col-xs-16 {
    width: 66.66667%;
  }
  .zmz-col-xs-offset-16 {
    margin-left: 66.66667%;
  }
  .zmz-col-xs-pull-16 {
    position: relative;
    right: 66.66667%;
  }
  .zmz-col-xs-push-16 {
    position: relative;
    left: 66.66667%;
  }
  .zmz-col-xs-17 {
    width: 70.83333%;
  }
  .zmz-col-xs-offset-17 {
    margin-left: 70.83333%;
  }
  .zmz-col-xs-pull-17 {
    position: relative;
    right: 70.83333%;
  }
  .zmz-col-xs-push-17 {
    position: relative;
    left: 70.83333%;
  }
  .zmz-col-xs-18 {
    width: 75%;
  }
  .zmz-col-xs-offset-18 {
    margin-left: 75%;
  }
  .zmz-col-xs-pull-18 {
    position: relative;
    right: 75%;
  }
  .zmz-col-xs-push-18 {
    position: relative;
    left: 75%;
  }
  .zmz-col-xs-19 {
    width: 79.16667%;
  }
  .zmz-col-xs-offset-19 {
    margin-left: 79.16667%;
  }
  .zmz-col-xs-pull-19 {
    position: relative;
    right: 79.16667%;
  }
  .zmz-col-xs-push-19 {
    position: relative;
    left: 79.16667%;
  }
  .zmz-col-xs-20 {
    width: 83.33333%;
  }
  .zmz-col-xs-offset-20 {
    margin-left: 83.33333%;
  }
  .zmz-col-xs-pull-20 {
    position: relative;
    right: 83.33333%;
  }
  .zmz-col-xs-push-20 {
    position: relative;
    left: 83.33333%;
  }
  .zmz-col-xs-21 {
    width: 87.5%;
  }
  .zmz-col-xs-offset-21 {
    margin-left: 87.5%;
  }
  .zmz-col-xs-pull-21 {
    position: relative;
    right: 87.5%;
  }
  .zmz-col-xs-push-21 {
    position: relative;
    left: 87.5%;
  }
  .zmz-col-xs-22 {
    width: 91.66667%;
  }
  .zmz-col-xs-offset-22 {
    margin-left: 91.66667%;
  }
  .zmz-col-xs-pull-22 {
    position: relative;
    right: 91.66667%;
  }
  .zmz-col-xs-push-22 {
    position: relative;
    left: 91.66667%;
  }
  .zmz-col-xs-23 {
    width: 95.83333%;
  }
  .zmz-col-xs-offset-23 {
    margin-left: 95.83333%;
  }
  .zmz-col-xs-pull-23 {
    position: relative;
    right: 95.83333%;
  }
  .zmz-col-xs-push-23 {
    position: relative;
    left: 95.83333%;
  }
  .zmz-col-xs-24 {
    width: 100%;
  }
  .zmz-col-xs-offset-24 {
    margin-left: 100%;
  }
  .zmz-col-xs-pull-24 {
    position: relative;
    right: 100%;
  }
  .zmz-col-xs-push-24 {
    position: relative;
    left: 100%;
  }
}
@media (min-width: 768px) {
  .zmz-col-sm-0 {
    width: 0%;
  }
  .zmz-col-sm-offset-0 {
    margin-left: 0;
  }
  .zmz-col-sm-pull-0 {
    position: relative;
    right: 0;
  }
  .zmz-col-sm-push-0 {
    position: relative;
    left: 0;
  }
  .zmz-col-sm-1 {
    width: 4.16667%;
  }
  .zmz-col-sm-offset-1 {
    margin-left: 4.16667%;
  }
  .zmz-col-sm-pull-1 {
    position: relative;
    right: 4.16667%;
  }
  .zmz-col-sm-push-1 {
    position: relative;
    left: 4.16667%;
  }
  .zmz-col-sm-2 {
    width: 8.33333%;
  }
  .zmz-col-sm-offset-2 {
    margin-left: 8.33333%;
  }
  .zmz-col-sm-pull-2 {
    position: relative;
    right: 8.33333%;
  }
  .zmz-col-sm-push-2 {
    position: relative;
    left: 8.33333%;
  }
  .zmz-col-sm-3 {
    width: 12.5%;
  }
  .zmz-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .zmz-col-sm-pull-3 {
    position: relative;
    right: 12.5%;
  }
  .zmz-col-sm-push-3 {
    position: relative;
    left: 12.5%;
  }
  .zmz-col-sm-4 {
    width: 16.66667%;
  }
  .zmz-col-sm-offset-4 {
    margin-left: 16.66667%;
  }
  .zmz-col-sm-pull-4 {
    position: relative;
    right: 16.66667%;
  }
  .zmz-col-sm-push-4 {
    position: relative;
    left: 16.66667%;
  }
  .zmz-col-sm-5 {
    width: 20.83333%;
  }
  .zmz-col-sm-offset-5 {
    margin-left: 20.83333%;
  }
  .zmz-col-sm-pull-5 {
    position: relative;
    right: 20.83333%;
  }
  .zmz-col-sm-push-5 {
    position: relative;
    left: 20.83333%;
  }
  .zmz-col-sm-6 {
    width: 25%;
  }
  .zmz-col-sm-offset-6 {
    margin-left: 25%;
  }
  .zmz-col-sm-pull-6 {
    position: relative;
    right: 25%;
  }
  .zmz-col-sm-push-6 {
    position: relative;
    left: 25%;
  }
  .zmz-col-sm-7 {
    width: 29.16667%;
  }
  .zmz-col-sm-offset-7 {
    margin-left: 29.16667%;
  }
  .zmz-col-sm-pull-7 {
    position: relative;
    right: 29.16667%;
  }
  .zmz-col-sm-push-7 {
    position: relative;
    left: 29.16667%;
  }
  .zmz-col-sm-8 {
    width: 33.33333%;
  }
  .zmz-col-sm-offset-8 {
    margin-left: 33.33333%;
  }
  .zmz-col-sm-pull-8 {
    position: relative;
    right: 33.33333%;
  }
  .zmz-col-sm-push-8 {
    position: relative;
    left: 33.33333%;
  }
  .zmz-col-sm-9 {
    width: 37.5%;
  }
  .zmz-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .zmz-col-sm-pull-9 {
    position: relative;
    right: 37.5%;
  }
  .zmz-col-sm-push-9 {
    position: relative;
    left: 37.5%;
  }
  .zmz-col-sm-10 {
    width: 41.66667%;
  }
  .zmz-col-sm-offset-10 {
    margin-left: 41.66667%;
  }
  .zmz-col-sm-pull-10 {
    position: relative;
    right: 41.66667%;
  }
  .zmz-col-sm-push-10 {
    position: relative;
    left: 41.66667%;
  }
  .zmz-col-sm-11 {
    width: 45.83333%;
  }
  .zmz-col-sm-offset-11 {
    margin-left: 45.83333%;
  }
  .zmz-col-sm-pull-11 {
    position: relative;
    right: 45.83333%;
  }
  .zmz-col-sm-push-11 {
    position: relative;
    left: 45.83333%;
  }
  .zmz-col-sm-12 {
    width: 50%;
  }
  .zmz-col-sm-offset-12 {
    margin-left: 50%;
  }
  .zmz-col-sm-pull-12 {
    position: relative;
    right: 50%;
  }
  .zmz-col-sm-push-12 {
    position: relative;
    left: 50%;
  }
  .zmz-col-sm-13 {
    width: 54.16667%;
  }
  .zmz-col-sm-offset-13 {
    margin-left: 54.16667%;
  }
  .zmz-col-sm-pull-13 {
    position: relative;
    right: 54.16667%;
  }
  .zmz-col-sm-push-13 {
    position: relative;
    left: 54.16667%;
  }
  .zmz-col-sm-14 {
    width: 58.33333%;
  }
  .zmz-col-sm-offset-14 {
    margin-left: 58.33333%;
  }
  .zmz-col-sm-pull-14 {
    position: relative;
    right: 58.33333%;
  }
  .zmz-col-sm-push-14 {
    position: relative;
    left: 58.33333%;
  }
  .zmz-col-sm-15 {
    width: 62.5%;
  }
  .zmz-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .zmz-col-sm-pull-15 {
    position: relative;
    right: 62.5%;
  }
  .zmz-col-sm-push-15 {
    position: relative;
    left: 62.5%;
  }
  .zmz-col-sm-16 {
    width: 66.66667%;
  }
  .zmz-col-sm-offset-16 {
    margin-left: 66.66667%;
  }
  .zmz-col-sm-pull-16 {
    position: relative;
    right: 66.66667%;
  }
  .zmz-col-sm-push-16 {
    position: relative;
    left: 66.66667%;
  }
  .zmz-col-sm-17 {
    width: 70.83333%;
  }
  .zmz-col-sm-offset-17 {
    margin-left: 70.83333%;
  }
  .zmz-col-sm-pull-17 {
    position: relative;
    right: 70.83333%;
  }
  .zmz-col-sm-push-17 {
    position: relative;
    left: 70.83333%;
  }
  .zmz-col-sm-18 {
    width: 75%;
  }
  .zmz-col-sm-offset-18 {
    margin-left: 75%;
  }
  .zmz-col-sm-pull-18 {
    position: relative;
    right: 75%;
  }
  .zmz-col-sm-push-18 {
    position: relative;
    left: 75%;
  }
  .zmz-col-sm-19 {
    width: 79.16667%;
  }
  .zmz-col-sm-offset-19 {
    margin-left: 79.16667%;
  }
  .zmz-col-sm-pull-19 {
    position: relative;
    right: 79.16667%;
  }
  .zmz-col-sm-push-19 {
    position: relative;
    left: 79.16667%;
  }
  .zmz-col-sm-20 {
    width: 83.33333%;
  }
  .zmz-col-sm-offset-20 {
    margin-left: 83.33333%;
  }
  .zmz-col-sm-pull-20 {
    position: relative;
    right: 83.33333%;
  }
  .zmz-col-sm-push-20 {
    position: relative;
    left: 83.33333%;
  }
  .zmz-col-sm-21 {
    width: 87.5%;
  }
  .zmz-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .zmz-col-sm-pull-21 {
    position: relative;
    right: 87.5%;
  }
  .zmz-col-sm-push-21 {
    position: relative;
    left: 87.5%;
  }
  .zmz-col-sm-22 {
    width: 91.66667%;
  }
  .zmz-col-sm-offset-22 {
    margin-left: 91.66667%;
  }
  .zmz-col-sm-pull-22 {
    position: relative;
    right: 91.66667%;
  }
  .zmz-col-sm-push-22 {
    position: relative;
    left: 91.66667%;
  }
  .zmz-col-sm-23 {
    width: 95.83333%;
  }
  .zmz-col-sm-offset-23 {
    margin-left: 95.83333%;
  }
  .zmz-col-sm-pull-23 {
    position: relative;
    right: 95.83333%;
  }
  .zmz-col-sm-push-23 {
    position: relative;
    left: 95.83333%;
  }
  .zmz-col-sm-24 {
    width: 100%;
  }
  .zmz-col-sm-offset-24 {
    margin-left: 100%;
  }
  .zmz-col-sm-pull-24 {
    position: relative;
    right: 100%;
  }
  .zmz-col-sm-push-24 {
    position: relative;
    left: 100%;
  }
}
@media (min-width: 992px) {
  .zmz-col-md-0 {
    width: 0%;
  }
  .zmz-col-md-offset-0 {
    margin-left: 0;
  }
  .zmz-col-md-pull-0 {
    position: relative;
    right: 0;
  }
  .zmz-col-md-push-0 {
    position: relative;
    left: 0;
  }
  .zmz-col-md-1 {
    width: 4.16667%;
  }
  .zmz-col-md-offset-1 {
    margin-left: 4.16667%;
  }
  .zmz-col-md-pull-1 {
    position: relative;
    right: 4.16667%;
  }
  .zmz-col-md-push-1 {
    position: relative;
    left: 4.16667%;
  }
  .zmz-col-md-2 {
    width: 8.33333%;
  }
  .zmz-col-md-offset-2 {
    margin-left: 8.33333%;
  }
  .zmz-col-md-pull-2 {
    position: relative;
    right: 8.33333%;
  }
  .zmz-col-md-push-2 {
    position: relative;
    left: 8.33333%;
  }
  .zmz-col-md-3 {
    width: 12.5%;
  }
  .zmz-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .zmz-col-md-pull-3 {
    position: relative;
    right: 12.5%;
  }
  .zmz-col-md-push-3 {
    position: relative;
    left: 12.5%;
  }
  .zmz-col-md-4 {
    width: 16.66667%;
  }
  .zmz-col-md-offset-4 {
    margin-left: 16.66667%;
  }
  .zmz-col-md-pull-4 {
    position: relative;
    right: 16.66667%;
  }
  .zmz-col-md-push-4 {
    position: relative;
    left: 16.66667%;
  }
  .zmz-col-md-5 {
    width: 20.83333%;
  }
  .zmz-col-md-offset-5 {
    margin-left: 20.83333%;
  }
  .zmz-col-md-pull-5 {
    position: relative;
    right: 20.83333%;
  }
  .zmz-col-md-push-5 {
    position: relative;
    left: 20.83333%;
  }
  .zmz-col-md-6 {
    width: 25%;
  }
  .zmz-col-md-offset-6 {
    margin-left: 25%;
  }
  .zmz-col-md-pull-6 {
    position: relative;
    right: 25%;
  }
  .zmz-col-md-push-6 {
    position: relative;
    left: 25%;
  }
  .zmz-col-md-7 {
    width: 29.16667%;
  }
  .zmz-col-md-offset-7 {
    margin-left: 29.16667%;
  }
  .zmz-col-md-pull-7 {
    position: relative;
    right: 29.16667%;
  }
  .zmz-col-md-push-7 {
    position: relative;
    left: 29.16667%;
  }
  .zmz-col-md-8 {
    width: 33.33333%;
  }
  .zmz-col-md-offset-8 {
    margin-left: 33.33333%;
  }
  .zmz-col-md-pull-8 {
    position: relative;
    right: 33.33333%;
  }
  .zmz-col-md-push-8 {
    position: relative;
    left: 33.33333%;
  }
  .zmz-col-md-9 {
    width: 37.5%;
  }
  .zmz-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .zmz-col-md-pull-9 {
    position: relative;
    right: 37.5%;
  }
  .zmz-col-md-push-9 {
    position: relative;
    left: 37.5%;
  }
  .zmz-col-md-10 {
    width: 41.66667%;
  }
  .zmz-col-md-offset-10 {
    margin-left: 41.66667%;
  }
  .zmz-col-md-pull-10 {
    position: relative;
    right: 41.66667%;
  }
  .zmz-col-md-push-10 {
    position: relative;
    left: 41.66667%;
  }
  .zmz-col-md-11 {
    width: 45.83333%;
  }
  .zmz-col-md-offset-11 {
    margin-left: 45.83333%;
  }
  .zmz-col-md-pull-11 {
    position: relative;
    right: 45.83333%;
  }
  .zmz-col-md-push-11 {
    position: relative;
    left: 45.83333%;
  }
  .zmz-col-md-12 {
    width: 50%;
  }
  .zmz-col-md-offset-12 {
    margin-left: 50%;
  }
  .zmz-col-md-pull-12 {
    position: relative;
    right: 50%;
  }
  .zmz-col-md-push-12 {
    position: relative;
    left: 50%;
  }
  .zmz-col-md-13 {
    width: 54.16667%;
  }
  .zmz-col-md-offset-13 {
    margin-left: 54.16667%;
  }
  .zmz-col-md-pull-13 {
    position: relative;
    right: 54.16667%;
  }
  .zmz-col-md-push-13 {
    position: relative;
    left: 54.16667%;
  }
  .zmz-col-md-14 {
    width: 58.33333%;
  }
  .zmz-col-md-offset-14 {
    margin-left: 58.33333%;
  }
  .zmz-col-md-pull-14 {
    position: relative;
    right: 58.33333%;
  }
  .zmz-col-md-push-14 {
    position: relative;
    left: 58.33333%;
  }
  .zmz-col-md-15 {
    width: 62.5%;
  }
  .zmz-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .zmz-col-md-pull-15 {
    position: relative;
    right: 62.5%;
  }
  .zmz-col-md-push-15 {
    position: relative;
    left: 62.5%;
  }
  .zmz-col-md-16 {
    width: 66.66667%;
  }
  .zmz-col-md-offset-16 {
    margin-left: 66.66667%;
  }
  .zmz-col-md-pull-16 {
    position: relative;
    right: 66.66667%;
  }
  .zmz-col-md-push-16 {
    position: relative;
    left: 66.66667%;
  }
  .zmz-col-md-17 {
    width: 70.83333%;
  }
  .zmz-col-md-offset-17 {
    margin-left: 70.83333%;
  }
  .zmz-col-md-pull-17 {
    position: relative;
    right: 70.83333%;
  }
  .zmz-col-md-push-17 {
    position: relative;
    left: 70.83333%;
  }
  .zmz-col-md-18 {
    width: 75%;
  }
  .zmz-col-md-offset-18 {
    margin-left: 75%;
  }
  .zmz-col-md-pull-18 {
    position: relative;
    right: 75%;
  }
  .zmz-col-md-push-18 {
    position: relative;
    left: 75%;
  }
  .zmz-col-md-19 {
    width: 79.16667%;
  }
  .zmz-col-md-offset-19 {
    margin-left: 79.16667%;
  }
  .zmz-col-md-pull-19 {
    position: relative;
    right: 79.16667%;
  }
  .zmz-col-md-push-19 {
    position: relative;
    left: 79.16667%;
  }
  .zmz-col-md-20 {
    width: 83.33333%;
  }
  .zmz-col-md-offset-20 {
    margin-left: 83.33333%;
  }
  .zmz-col-md-pull-20 {
    position: relative;
    right: 83.33333%;
  }
  .zmz-col-md-push-20 {
    position: relative;
    left: 83.33333%;
  }
  .zmz-col-md-21 {
    width: 87.5%;
  }
  .zmz-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .zmz-col-md-pull-21 {
    position: relative;
    right: 87.5%;
  }
  .zmz-col-md-push-21 {
    position: relative;
    left: 87.5%;
  }
  .zmz-col-md-22 {
    width: 91.66667%;
  }
  .zmz-col-md-offset-22 {
    margin-left: 91.66667%;
  }
  .zmz-col-md-pull-22 {
    position: relative;
    right: 91.66667%;
  }
  .zmz-col-md-push-22 {
    position: relative;
    left: 91.66667%;
  }
  .zmz-col-md-23 {
    width: 95.83333%;
  }
  .zmz-col-md-offset-23 {
    margin-left: 95.83333%;
  }
  .zmz-col-md-pull-23 {
    position: relative;
    right: 95.83333%;
  }
  .zmz-col-md-push-23 {
    position: relative;
    left: 95.83333%;
  }
  .zmz-col-md-24 {
    width: 100%;
  }
  .zmz-col-md-offset-24 {
    margin-left: 100%;
  }
  .zmz-col-md-pull-24 {
    position: relative;
    right: 100%;
  }
  .zmz-col-md-push-24 {
    position: relative;
    left: 100%;
  }
}
@media (min-width: 1200px) {
  .zmz-col-lg-0 {
    width: 0%;
  }
  .zmz-col-lg-offset-0 {
    margin-left: 0;
  }
  .zmz-col-lg-pull-0 {
    position: relative;
    right: 0;
  }
  .zmz-col-lg-push-0 {
    position: relative;
    left: 0;
  }
  .zmz-col-lg-1 {
    width: 4.16667%;
  }
  .zmz-col-lg-offset-1 {
    margin-left: 4.16667%;
  }
  .zmz-col-lg-pull-1 {
    position: relative;
    right: 4.16667%;
  }
  .zmz-col-lg-push-1 {
    position: relative;
    left: 4.16667%;
  }
  .zmz-col-lg-2 {
    width: 8.33333%;
  }
  .zmz-col-lg-offset-2 {
    margin-left: 8.33333%;
  }
  .zmz-col-lg-pull-2 {
    position: relative;
    right: 8.33333%;
  }
  .zmz-col-lg-push-2 {
    position: relative;
    left: 8.33333%;
  }
  .zmz-col-lg-3 {
    width: 12.5%;
  }
  .zmz-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .zmz-col-lg-pull-3 {
    position: relative;
    right: 12.5%;
  }
  .zmz-col-lg-push-3 {
    position: relative;
    left: 12.5%;
  }
  .zmz-col-lg-4 {
    width: 16.66667%;
  }
  .zmz-col-lg-offset-4 {
    margin-left: 16.66667%;
  }
  .zmz-col-lg-pull-4 {
    position: relative;
    right: 16.66667%;
  }
  .zmz-col-lg-push-4 {
    position: relative;
    left: 16.66667%;
  }
  .zmz-col-lg-5 {
    width: 20.83333%;
  }
  .zmz-col-lg-offset-5 {
    margin-left: 20.83333%;
  }
  .zmz-col-lg-pull-5 {
    position: relative;
    right: 20.83333%;
  }
  .zmz-col-lg-push-5 {
    position: relative;
    left: 20.83333%;
  }
  .zmz-col-lg-6 {
    width: 25%;
  }
  .zmz-col-lg-offset-6 {
    margin-left: 25%;
  }
  .zmz-col-lg-pull-6 {
    position: relative;
    right: 25%;
  }
  .zmz-col-lg-push-6 {
    position: relative;
    left: 25%;
  }
  .zmz-col-lg-7 {
    width: 29.16667%;
  }
  .zmz-col-lg-offset-7 {
    margin-left: 29.16667%;
  }
  .zmz-col-lg-pull-7 {
    position: relative;
    right: 29.16667%;
  }
  .zmz-col-lg-push-7 {
    position: relative;
    left: 29.16667%;
  }
  .zmz-col-lg-8 {
    width: 33.33333%;
  }
  .zmz-col-lg-offset-8 {
    margin-left: 33.33333%;
  }
  .zmz-col-lg-pull-8 {
    position: relative;
    right: 33.33333%;
  }
  .zmz-col-lg-push-8 {
    position: relative;
    left: 33.33333%;
  }
  .zmz-col-lg-9 {
    width: 37.5%;
  }
  .zmz-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .zmz-col-lg-pull-9 {
    position: relative;
    right: 37.5%;
  }
  .zmz-col-lg-push-9 {
    position: relative;
    left: 37.5%;
  }
  .zmz-col-lg-10 {
    width: 41.66667%;
  }
  .zmz-col-lg-offset-10 {
    margin-left: 41.66667%;
  }
  .zmz-col-lg-pull-10 {
    position: relative;
    right: 41.66667%;
  }
  .zmz-col-lg-push-10 {
    position: relative;
    left: 41.66667%;
  }
  .zmz-col-lg-11 {
    width: 45.83333%;
  }
  .zmz-col-lg-offset-11 {
    margin-left: 45.83333%;
  }
  .zmz-col-lg-pull-11 {
    position: relative;
    right: 45.83333%;
  }
  .zmz-col-lg-push-11 {
    position: relative;
    left: 45.83333%;
  }
  .zmz-col-lg-12 {
    width: 50%;
  }
  .zmz-col-lg-offset-12 {
    margin-left: 50%;
  }
  .zmz-col-lg-pull-12 {
    position: relative;
    right: 50%;
  }
  .zmz-col-lg-push-12 {
    position: relative;
    left: 50%;
  }
  .zmz-col-lg-13 {
    width: 54.16667%;
  }
  .zmz-col-lg-offset-13 {
    margin-left: 54.16667%;
  }
  .zmz-col-lg-pull-13 {
    position: relative;
    right: 54.16667%;
  }
  .zmz-col-lg-push-13 {
    position: relative;
    left: 54.16667%;
  }
  .zmz-col-lg-14 {
    width: 58.33333%;
  }
  .zmz-col-lg-offset-14 {
    margin-left: 58.33333%;
  }
  .zmz-col-lg-pull-14 {
    position: relative;
    right: 58.33333%;
  }
  .zmz-col-lg-push-14 {
    position: relative;
    left: 58.33333%;
  }
  .zmz-col-lg-15 {
    width: 62.5%;
  }
  .zmz-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .zmz-col-lg-pull-15 {
    position: relative;
    right: 62.5%;
  }
  .zmz-col-lg-push-15 {
    position: relative;
    left: 62.5%;
  }
  .zmz-col-lg-16 {
    width: 66.66667%;
  }
  .zmz-col-lg-offset-16 {
    margin-left: 66.66667%;
  }
  .zmz-col-lg-pull-16 {
    position: relative;
    right: 66.66667%;
  }
  .zmz-col-lg-push-16 {
    position: relative;
    left: 66.66667%;
  }
  .zmz-col-lg-17 {
    width: 70.83333%;
  }
  .zmz-col-lg-offset-17 {
    margin-left: 70.83333%;
  }
  .zmz-col-lg-pull-17 {
    position: relative;
    right: 70.83333%;
  }
  .zmz-col-lg-push-17 {
    position: relative;
    left: 70.83333%;
  }
  .zmz-col-lg-18 {
    width: 75%;
  }
  .zmz-col-lg-offset-18 {
    margin-left: 75%;
  }
  .zmz-col-lg-pull-18 {
    position: relative;
    right: 75%;
  }
  .zmz-col-lg-push-18 {
    position: relative;
    left: 75%;
  }
  .zmz-col-lg-19 {
    width: 79.16667%;
  }
  .zmz-col-lg-offset-19 {
    margin-left: 79.16667%;
  }
  .zmz-col-lg-pull-19 {
    position: relative;
    right: 79.16667%;
  }
  .zmz-col-lg-push-19 {
    position: relative;
    left: 79.16667%;
  }
  .zmz-col-lg-20 {
    width: 83.33333%;
  }
  .zmz-col-lg-offset-20 {
    margin-left: 83.33333%;
  }
  .zmz-col-lg-pull-20 {
    position: relative;
    right: 83.33333%;
  }
  .zmz-col-lg-push-20 {
    position: relative;
    left: 83.33333%;
  }
  .zmz-col-lg-21 {
    width: 87.5%;
  }
  .zmz-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .zmz-col-lg-pull-21 {
    position: relative;
    right: 87.5%;
  }
  .zmz-col-lg-push-21 {
    position: relative;
    left: 87.5%;
  }
  .zmz-col-lg-22 {
    width: 91.66667%;
  }
  .zmz-col-lg-offset-22 {
    margin-left: 91.66667%;
  }
  .zmz-col-lg-pull-22 {
    position: relative;
    right: 91.66667%;
  }
  .zmz-col-lg-push-22 {
    position: relative;
    left: 91.66667%;
  }
  .zmz-col-lg-23 {
    width: 95.83333%;
  }
  .zmz-col-lg-offset-23 {
    margin-left: 95.83333%;
  }
  .zmz-col-lg-pull-23 {
    position: relative;
    right: 95.83333%;
  }
  .zmz-col-lg-push-23 {
    position: relative;
    left: 95.83333%;
  }
  .zmz-col-lg-24 {
    width: 100%;
  }
  .zmz-col-lg-offset-24 {
    margin-left: 100%;
  }
  .zmz-col-lg-pull-24 {
    position: relative;
    right: 100%;
  }
  .zmz-col-lg-push-24 {
    position: relative;
    left: 100%;
  }
}
@font-face {
  font-family: iconfont;
  src: url(fonts/iconfont.eot?t=15608598808201);
  src: url(fonts/iconfont.eot?t=15608598808201#iefix) format('embedded-opentype'), url(fonts/iconfont.woff2?t=15608598808201) format('woff2'), url(fonts/iconfont.woff?t=15608598808201) format('woff'), url(fonts/iconfont.ttf?t=15608598808201) format('truetype'), url(fonts/iconfont.svg?t=15608598808201#iconfont) format('svg');
}
[class*=zmz-icon--],
[class^=zmz-icon] {
  font-family: iconfont!important;
  font-size: 14px;
  font-style: normal;
  vertical-align: baseline;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.zmz-icon--error:before {
  content: "\e663";
}
.zmz-icon--info:before {
  content: "\e67d";
}
.zmz-icon--success:before {
  content: "\e65b";
}
.zmz-icon--doubt:before {
  content: "\e64e";
}
.zmz-icon--fill-close:before {
  content: "\e663";
}
.zmz-icon--calendar:before {
  content: "\e650";
}
.zmz-icon--doublearrow-left:before {
  content: "\eb0d";
}
.zmz-icon--doublearrow-right:before {
  content: "\eb0e";
}
.zmz-icon--arrow-bottom:before {
  content: "\eb0f";
}
.zmz-icon--arrow-top:before {
  content: "\eb12";
}
.zmz-icon--arrow-left:before {
  content: "\eb11";
}
.zmz-icon--arrow-right:before {
  content: "\eb10";
}
.zmz-icon--close:before {
  content: "\eb13";
}
.zmz-icon--file-error:before {
  content: "\e658";
}
.zmz-icon--pricefill:before {
  content: "\e66a";
}
.zmz-icon--unlink:before {
  content: "\e6b3";
}
.zmz-icon--zfb-b:before {
  content: "\e609";
}
.zmz-icon--zfb-c:before {
  content: "\e63b";
}
.zmz-icon--qq-c:before {
  content: "\e6a0";
}
.zmz-icon--key:before {
  content: "\e611";
}
.zmz-icon--doubtfill:before {
  content: "\e64e";
}
.zmz-icon--systemlog:before {
  content: "\e691";
}
.zmz-icon--fill-horn:before {
  content: "\e60d";
}
.zmz-icon--arrowup:before {
  content: "\e643";
}
.zmz-icon--home:before {
  content: "\e65c";
}
.zmz-icon--arrowloop:before {
  content: "\e6a1";
}
.zmz-icon--arrowcros:before {
  content: "\e6af";
}
.zmz-icon--refresh:before {
  content: "\e6b2";
}
.zmz-icon--delopen:before {
  content: "\e640";
}
.zmz-icon--wpay:before {
  content: "\e635";
}
.zmz-icon--gupload:before {
  content: "\e64a";
}
.zmz-icon--eyesfill:before {
  content: "\e693";
}
.zmz-icon--code:before {
  content: "\e61f";
}
.zmz-icon--wechat:before {
  content: "\e62f";
}
.zmz-icon--guarantee:before {
  content: "\e623";
}
.zmz-icon--wshake:before {
  content: "\e637";
}
.zmz-icon--txweibo:before {
  content: "\e66b";
}
.zmz-icon--price-s:before {
  content: "\e648";
}
.zmz-icon--price:before {
  content: "\e652";
}
.zmz-icon--movefill:before {
  content: "\e64f";
}
.zmz-icon--renren:before {
  content: "\e65e";
}
.zmz-icon--laugh:before {
  content: "\e654";
}
.zmz-icon--location2:before {
  content: "\e65a";
}
.zmz-icon--ios:before {
  content: "\e604";
}
.zmz-icon--menu:before {
  content: "\e6e9";
}
.zmz-icon--security:before {
  content: "\e65f";
}
.zmz-icon--qzone-d:before {
  content: "\e664";
}
.zmz-icon--tag:before {
  content: "\e666";
}
.zmz-icon--tag2:before {
  content: "\e69f";
}
.zmz-icon--letter-r:before {
  content: "\e696";
}
.zmz-icon--security2:before {
  content: "\e678";
}
.zmz-icon--wechat-c:before {
  content: "\e67d";
}
.zmz-icon--qzone-c:before {
  content: "\e67f";
}
.zmz-icon--log:before {
  content: "\e684";
}
.zmz-icon--laugh-c:before {
  content: "\e686";
}
.zmz-icon--sad-cl:before {
  content: "\e687";
}
.zmz-icon--sina-weibo:before {
  content: "\e692";
}
.zmz-icon--fan:before {
  content: "\e695";
}
.zmz-icon--tv:before {
  content: "\e697";
}
.zmz-icon--life-circle:before {
  content: "\e699";
}
.zmz-icon--key-c:before {
  content: "\e69b";
}
.zmz-icon--tag3:before {
  content: "\e69c";
}
.zmz-icon--file-text:before {
  content: "\e69e";
}
.zmz-icon--set:before {
  content: "\e78e";
}
.zmz-icon--horn-close:before {
  content: "\e6a7";
}
.zmz-icon--bulb:before {
  content: "\e6a8";
}
.zmz-icon--hot:before {
  content: "\e6ec";
}
.zmz-icon--mobile-office:before {
  content: "\e6ab";
}
.zmz-icon--news:before {
  content: "\e6b0";
}
.zmz-icon--upload:before {
  content: "\e6c3";
}
.zmz-icon--home2:before {
  content: "\e661";
}
.zmz-icon--folder:before {
  content: "\e6c4";
}
.zmz-icon--gift:before {
  content: "\e6cc";
}
.zmz-icon--horn2:before {
  content: "\e6cd";
}
.zmz-icon--link:before {
  content: "\e6cf";
}
.zmz-icon--img:before {
  content: "\e6d4";
}
.zmz-icon--unlink2:before {
  content: "\e6d5";
}
.zmz-icon--headset:before {
  content: "\e6d6";
}
.zmz-icon--set2:before {
  content: "\e644";
}
.zmz-icon--close2:before {
  content: "\e6d7";
}
.zmz-icon--security-f:before {
  content: "\e6d8";
}
.zmz-icon--sina:before {
  content: "\e61d";
}
.zmz-icon--warning-t:before {
  content: "\e6db";
}
.zmz-icon--upload-c:before {
  content: "\e6dc";
}
.zmz-icon--warning:before {
  content: "\e662";
}
.zmz-icon--link2:before {
  content: "\e6e0";
}
.zmz-icon--nurse:before {
  content: "\e6e1";
}
.zmz-icon--xyunited:before {
  content: "\e6e3";
}
.zmz-icon--download:before {
  content: "\e6e5";
}
.zmz-icon--user:before {
  content: "\e634";
}
.zmz-icon--film:before {
  content: "\e6e8";
}
.zmz-icon--hot2:before {
  content: "\e6ea";
}
.zmz-icon--hot3:before {
  content: "\e6eb";
}
.zmz-icon--upload-tv:before {
  content: "\e6ee";
}
.zmz-icon--download-tv:before {
  content: "\e6ef";
}
.zmz-icon--gift2:before {
  content: "\e6f0";
}
.zmz-icon--tag4:before {
  content: "\e6f3";
}
.zmz-icon--location3:before {
  content: "\e6f4";
}
.zmz-icon--log2:before {
  content: "\e6f5";
}
.zmz-icon--horn-open:before {
  content: "\e6f6";
}
.zmz-icon--wechat-d:before {
  content: "\e7b4";
}
.zmz-icon--folder-f:before {
  content: "\e6fa";
}
.zmz-icon--wrench-l:before {
  content: "\e8d6";
}
.zmz-icon--wrench-f:before {
  content: "\e8d7";
}
.zmz-icon--reelected:before {
  content: "\e6fc";
}
.zmz-icon--link3:before {
  content: "\e6ff";
}
.zmz-icon--news-f:before {
  content: "\e707";
}
.zmz-icon--news-l:before {
  content: "\e7a0";
}
.zmz-icon--service:before {
  content: "\e70a";
}
.zmz-icon--link4:before {
  content: "\e712";
}
.zmz-icon--location4:before {
  content: "\e713";
}
.zmz-icon--camera:before {
  content: "\e714";
}
.zmz-icon--notebook:before {
  content: "\e715";
}
.zmz-icon--headset2:before {
  content: "\e716";
}
.zmz-icon--balloon:before {
  content: "\e717";
}
.zmz-icon--upload2:before {
  content: "\e719";
}
.zmz-icon--location5:before {
  content: "\e71a";
}
.zmz-icon--file-code:before {
  content: "\e71b";
}
.zmz-icon--article-l:before {
  content: "\e71c";
}
.zmz-icon--article-f:before {
  content: "\e71d";
}
.zmz-icon--book-open:before {
  content: "\e71f";
}
.zmz-icon--home-edit:before {
  content: "\e721";
}
.zmz-icon--warning-l:before {
  content: "\e728";
}
.zmz-icon--tvdata:before {
  content: "\e729";
}
.zmz-icon--folder-l:before {
  content: "\e72a";
}
.zmz-icon--service2:before {
  content: "\e72b";
}
.zmz-icon--deletion:before {
  content: "\e72c";
}
.zmz-icon--security3:before {
  content: "\e72d";
}
.zmz-icon--onlineclassroom:before {
  content: "\e72f";
}
.zmz-icon--doctor:before {
  content: "\e730";
}
.zmz-icon--popularity:before {
  content: "\e731";
}
.zmz-icon--log3:before {
  content: "\e733";
}
.zmz-icon--gourd:before {
  content: "\e734";
}
.zmz-icon--article-open:before {
  content: "\e735";
}
.zmz-icon--code2:before {
  content: "\e739";
}
.zmz-icon--prohibit:before {
  content: "\e73b";
}
.zmz-icon--download-f:before {
  content: "\e73e";
}
.zmz-icon--funnel:before {
  content: "\e741";
}
.zmz-icon--monitor:before {
  content: "\e743";
}
.zmz-icon--safety-report:before {
  content: "\e744";
}
.zmz-icon--file-search:before {
  content: "\e745";
}
.zmz-icon--file-correct:before {
  content: "\e746";
}
.zmz-icon--file-set:before {
  content: "\e747";
}
.zmz-icon--move:before {
  content: "\e748";
}
.zmz-icon--add-l:before {
  content: "\e749";
}
.zmz-icon--camera-upload:before {
  content: "\e74a";
}
.zmz-icon--smallbell-f:before {
  content: "\e74c";
}
.zmz-icon--time:before {
  content: "\e74d";
}
.zmz-icon--file-activity:before {
  content: "\e74e";
}
.zmz-icon--camera2:before {
  content: "\e751";
}
.zmz-icon--user-add:before {
  content: "\e756";
}
.zmz-icon--lock:before {
  content: "\e75b";
}
.zmz-icon--file-edit:before {
  content: "\e75c";
}
.zmz-icon--add:before {
  content: "\e75d";
}
.zmz-icon--qgroup:before {
  content: "\e62f";
}
.zmz-icon--confirm:before {
  content: "\e765";
}
.zmz-icon--prohibit-l:before {
  content: "\e76a";
}
.zmz-icon--search:before {
  content: "\e608";
}
.zmz-icon--message-push:before {
  content: "\e771";
}
.zmz-icon--message:before {
  content: "\e774";
}
.zmz-icon--harddisk:before {
  content: "\e775";
}
.zmz-icon--harddisk2:before {
  content: "\e776";
}
.zmz-icon--moustache:before {
  content: "\e778";
}
.zmz-icon--checkmark:before {
  content: "\e77b";
}
.zmz-icon--danger:before {
  content: "\e77d";
}
.zmz-icon--police:before {
  content: "\e77e";
}
.zmz-icon--message-success:before {
  content: "\e77f";
}
.zmz-icon--move-enlarge:before {
  content: "\e782";
}
.zmz-icon--harddisk-download:before {
  content: "\e7a3";
}
.zmz-icon--qq-l:before {
  content: "\e789";
}
.zmz-icon--close-l:before {
  content: "\e790";
}
.zmz-icon--mobile-push:before {
  content: "\e8a0";
}
.zmz-icon--mobile-confirm:before {
  content: "\e791";
}
.zmz-icon--copylinks-l:before {
  content: "\e796";
}
.zmz-icon--copylinks-f:before {
  content: "\e798";
}
.zmz-icon--close-f:before {
  content: "\e799";
}
.zmz-icon--add-f:before {
  content: "\e79a";
}
.zmz-icon--eye-l:before {
  content: "\e7a5";
}
.zmz-icon--user-f:before {
  content: "\e625";
}
.zmz-icon--laugh-f:before {
  content: "\e7d7";
}
.zmz-icon--laugh-l:before {
  content: "\e7d9";
}
.zmz-icon--choice-f:before {
  content: "\e7c3";
}
.zmz-icon--choice-l:before {
  content: "\e7c7";
}
.zmz-icon--letter-error:before {
  content: "\e7c8";
}
.zmz-icon--file:before {
  content: "\e7d4";
}
.zmz-icon--workstation:before {
  content: "\e84a";
}
.zmz-icon--circlefriends:before {
  content: "\e7d8";
}
.zmz-icon--edit-key:before {
  content: "\e7e3";
}
.zmz-icon--mobile:before {
  content: "\e7e4";
}
.zmz-icon--redflag:before {
  content: "\e7ef";
}
.zmz-icon--confirm-l:before {
  content: "\e7f9";
}
.zmz-icon--zhifubao:before {
  content: "\e7fa";
}
.zmz-icon--file-upload2:before {
  content: "\e7fb";
}
.zmz-icon--delete:before {
  content: "\e80e";
}
.zmz-icon--arrow-top-f:before {
  content: "\e828";
}
.zmz-icon--arrow-left-f:before {
  content: "\e829";
}
.zmz-icon--arrow-right-f:before {
  content: "\e82a";
}
.zmz-icon--arrow-bottom-f:before {
  content: "\e82b";
}
.zmz-icon--5g:before {
  content: "\e82f";
}
.zmz-icon--code-management:before {
  content: "\e830";
}
.zmz-icon--confirm1:before {
  content: "\e831";
}
.zmz-icon--internet:before {
  content: "\e832";
}
.zmz-icon--quan:before {
  content: "\e836";
}
.zmz-icon--code-text:before {
  content: "\e837";
}
.zmz-icon--books:before {
  content: "\e83a";
}
.zmz-icon--edit:before {
  content: "\e83b";
}
.zmz-icon--move2:before {
  content: "\e83d";
}
.zmz-icon--download2:before {
  content: "\e83e";
}
.zmz-icon--upload3:before {
  content: "\ec81";
}
.zmz-icon--funnel-tr-f:before {
  content: "\ec82";
}
.zmz-icon--funnel-r-f:before {
  content: "\ec83";
}
.zmz-icon--funnel-rb-f:before {
  content: "\ec84";
}
.zmz-icon--funnel-b-f:before {
  content: "\ec85";
}
.zmz-icon--funnel-bl-f:before {
  content: "\ec86";
}
.zmz-icon--funnel-l-f:before {
  content: "\ec87";
}
.zmz-icon--funnel-lt-f:before {
  content: "\ec88";
}
.zmz-icon--funnel-b-l:before {
  content: "\ec89";
}
.zmz-icon--funnel-br-l:before {
  content: "\ec8a";
}
.zmz-icon--funnel-r-l:before {
  content: "\ec8b";
}
.zmz-icon--funnel-tr-l:before {
  content: "\ec8c";
}
.zmz-icon--funnel-t-l:before {
  content: "\ec8d";
}
.zmz-icon--funnel-tl-l:before {
  content: "\ec8e";
}
.zmz-icon--funnel-l-l:before {
  content: "\ec8f";
}
.zmz-icon--funnel-lb-l:before {
  content: "\ec90";
}
.zmz-icon--book2:before {
  content: "\ec9f";
}
.zmz-icon--book3:before {
  content: "\eca0";
}
.zmz-icon--share-l:before {
  content: "\eca5";
}
.zmz-icon--share-b:before {
  content: "\eca6";
}
.zmz-icon--share-t:before {
  content: "\eca7";
}
.zmz-icon--share-r:before {
  content: "\eca8";
}
.zmz-icon--arrow-b-l:before {
  content: "\ecaa";
}
.zmz-icon--arrow-t-l:before {
  content: "\ecab";
}
.zmz-icon--arrow-l-l:before {
  content: "\ecac";
}
.zmz-icon--arrow-r-l:before {
  content: "\ecad";
}
.zmz-icon--arrow-tr-f:before {
  content: "\ecae";
}
.zmz-icon--arrow-t-f:before {
  content: "\ecaf";
}
.zmz-icon--arrow-tl-f:before {
  content: "\ecb0";
}
.zmz-icon--arrow-l-f:before {
  content: "\ecb1";
}
.zmz-icon--arrow-lb-f:before {
  content: "\ecb2";
}
.zmz-icon--arrow-b-f:before {
  content: "\ecb3";
}
.zmz-icon--arrow-br-f:before {
  content: "\ecb4";
}
.zmz-icon--arrow-r-f:before {
  content: "\ecb5";
}
.zmz-icon--file-upload:before {
  content: "\ecc4";
}
.zmz-icon--arrowleft-l:before {
  content: "\ecd1";
}
.zmz-icon--arrowright-l:before {
  content: "\ecd2";
}
.zmz-icon--arrowtop-l:before {
  content: "\ecd3";
}
.zmz-icon--arrowbottom-l:before {
  content: "\ecd4";
}
.zmz-icon--arrowbottom-f:before {
  content: "\ecd5";
}
.zmz-icon--arrowleft-f:before {
  content: "\ecd6";
}
.zmz-icon--arrowtop-f:before {
  content: "\ecd7";
}
.zmz-icon--arrowright-f:before {
  content: "\ecd8";
}
.zmz-icon--logout:before {
  content: "\ecfd";
}
.zmz-icon--arrow2-r-f:before {
  content: "\ecfe";
}
.zmz-icon--arrow2-tr-f:before {
  content: "\ecff";
}
.zmz-icon--arrow2-t-f:before {
  content: "\ed00";
}
.zmz-icon--arrow2-tl-f:before {
  content: "\ed01";
}
.zmz-icon--arrow2-l-f:before {
  content: "\ed02";
}
.zmz-icon--arrow2-lb-f:before {
  content: "\ed03";
}
.zmz-icon--arrow2-b-f:before {
  content: "\ed04";
}
.zmz-icon--arrow2-rb-f:before {
  content: "\ed05";
}
.zmz-tag {
  padding: 1px 8px;
  font-size: 0;
  line-height: 1.5;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  color: #3f536e;
  background-color: #f7f7f7;
}
.zmz-tag__close,
.zmz-tag__text {
  font-size: 12px;
}
.zmz-demo--1__left .zmz-article--comment .quill-deitor__box .zmz-button-submit,
.zmz-demo--1__left .zmz-article--details .zmz-article__footer .zmz-article__footer__bottom {
  text-align: right;
}
.zmz-tag__text {
  margin-right: 2px;
}
.zmz-tag--default {
  color: #737373;
  border-color: #d6d6d6;
  background-color: #fff;
}
.zmz-tag--info {
  color: #fff;
  border-color: #a0a0a0;
  background-color: #a0a0a0;
}
.zmz-tag--success {
  background-color: #2eca7b;
  border-color: #2eca7b;
  color: #f1f1f1;
}
.zmz-tag--confirm {
  background-color: #53a2f3;
  border-color: #53a2f3;
  color: #f1f1f1;
}
.zmz-tag--warning {
  background-color: #d89358;
  border-color: #d89358;
  color: #f1f1f1;
}
.zmz-tag--error {
  background-color: #f15c5c;
  border-color: #f15c5c;
  color: #f1f1f1;
}
.zmz-demo--1__header {
  background-color: #fff;
  box-shadow: 0 10px 60px 0 rgba(29, 29, 31, 0.07);
  opacity: 0.98;
  position: relative!important;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 100;
}
.zmz-demo--1__left .zmz-article--comment,
.zmz-demo--1__left .zmz-article--details,
.zmz-demo--1__left .zmz-media,
.zmz-demo--1__notice .zmz-alert,
.zmz-demo--search {
  box-shadow: 0 4px 30px 0 rgba(223, 225, 230, 0.5);
}
.zmz-demo--1__header .zmz-header-container {
  height: 80px;
}
.zmz-demo--1__header .zmz-header-container .zmz-header-left a {
  display: block;
}
.zmz-demo--1__header .zmz-header-container .zmz-header-left a img {
  height: 80px;
  opacity: 0.8;
  vertical-align: middle;
}
.zmz-demo--1__header .zmz-header-container .zmz-header-right {
  clear: both;
  float: inherit;
}
.zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-demo--button {
  position: absolute;
  top: 0;
  right: 0;
}
.zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-demo--nav {
  display: block;
  background: #f4f5f9;
}
.zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-demo--nav .zmz-nav__demo {
  width: 100%;
}
.zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-demo--nav .zmz-nav__demo .nav-item {
  float: none;
  display: block;
  height: 40px;
  line-height: 40px;
}
.zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-demo--nav .zmz-nav__login {
  display: block;
  padding: 10px;
}
.zmz-demo--1__left {
  margin-top: 60px;
}
.zmz-demo--1__left .zmz-article--breadcrumb {
  margin-bottom: 20px;
  font-size: 16px;
}
.zmz-demo--1__left .zmz-article--breadcrumb .zmz-breadcrumb__item .zmz-breadcrumb__text {
  font-weight: 400;
}
.zmz-demo--1__left .zmz-article--details {
  border: 1px solid #F4F5F9;
  padding: 30px;
  margin-bottom: 20px;
}
.zmz-demo--1__left .zmz-article--details .zmz-article__header {
  font-size: 20px;
  margin-bottom: 20px;
  color: #565353;
  font-weight: 700;
}
.zmz-demo--1__left .zmz-article--details .zmz-article__user--info {
  margin-bottom: 20px;
}
.zmz-demo--1__left .zmz-article--details .zmz-article__user--info span {
  margin-right: 20px;
  color: #afafaf;
}
.zmz-demo--1__left .zmz-article--details .zmz-article__user--info span i {
  font-size: 20px;
  margin-right: 10px;
}
.zmz-demo--1__left .zmz-article--details .zmz-article__section {
  margin-bottom: 20px;
}
.zmz-demo--1__left .zmz-article--details .zmz-article__footer .zmz-article__footer__top {
  margin-bottom: 40px;
  color: rgba(215, 91, 103, 0.66);
}
.zmz-demo--1__left .zmz-article--details .zmz-article__footer .zmz-article__footer__bottom .footer__bottom__1 {
  display: inline-block;
  margin-right: 20px;
  color: #afafaf;
}
.zmz-demo--1__left .zmz-article--details .zmz-article__footer .zmz-article__footer__bottom .footer__bottom__1 span {
  margin: 0 10px;
}
.zmz-demo--1__left .zmz-article--details .zmz-article__footer .zmz-article__footer__bottom .footer__bottom__2 {
  display: inline-block;
  color: #afafaf;
}
.zmz-demo--1__left .zmz-article--details .zmz-article__footer .zmz-article__footer__bottom .footer__bottom__2 span {
  padding: 5px 10px;
  background: #d8878f;
  color: #fff;
  border-radius: 6px;
  margin: 0 10px;
  display: inline-block;
}
.zmz-demo--1__left .zmz-article--comment .quill-deitor__box,
.zmz-demo--1__left .zmz-article--comment .quill-deitor__box .zmz-article__author {
  margin-bottom: 20px;
}
.zmz-demo--1__left .zmz-article--content {
  color: #827c7c;
  line-height: 1.5;
}
.zmz-demo--1__left .zmz-article--comment {
  padding: 35px;
  background: #fff;
}
.zmz-demo--1__left .zmz-article--comment .quill-deitor__box .zmz-button-submit button {
  background-color: #d8878f;
  border-color: #d8878f;
  color: #fff;
}
.zmz-demo--1__left .zmz-article--comment .quill-deitor__box .zmz-article__author span {
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  background: #87d8c2;
  border-radius: 50%;
  margin-right: 10px;
}
.zmz-demo--1__left .zmz-article--comment .quill-deitor__box .zmz-article__author p {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #868686;
}
.zmz-demo--1__left .zmz-article--comment .quill-deitor__box .zmz-article__post {
  margin-top: 20px;
  text-align: right;
}
.zmz-demo--1__left .zmz-article--comment .quill-deitor__box .zmz-article__post span {
  display: inline-block;
  vertical-align: middle;
  background: #87d8c2;
  padding: 6px 10px;
  margin: 0 10px;
  border-radius: 6px;
  color: #fff;
}
.zmz-demo--1__left .zmz-article--comment .zmz-comment__box .zmz-comment__title {
  letter-spacing: 2px;
  font-size: 20px;
  margin-bottom: 50px;
  color: #827c7c;
}
.zmz-demo--1__left .zmz-article--comment .zmz-comment__box .zmz-comment__title span {
  font-weight: 700;
  font-size: 30px;
  color: #d8878f;
  margin-right: 10px;
  display: inline-block;
}
.zmz-demo--1__left .zmz-article--comment .zmz-comment__content .zmz-comment__li {
  padding: 20px 0;
}
.zmz-demo--1__left .zmz-article--comment .zmz-comment__content .zmz-comment__li + .zmz-comment__li {
  border-top: 1px solid #fbfbfb;
}
.zmz-demo--1__left .zmz-article--comment .zmz-comment__content .zmz-comment__li .comment-item--info {
  position: relative;
}
.zmz-demo--1__left .zmz-article--comment .zmz-comment__content .zmz-comment__li .comment-item--info > span {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f4f5f9;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.zmz-demo--1__left .zmz-article--comment .zmz-comment__content .zmz-comment__li .comment-item--info > p {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #868686;
}
.zmz-demo--1__left .zmz-article--comment .zmz-comment__content .zmz-comment__li .comment-item--info > p small {
  position: absolute;
  right: 0;
}
.zmz-demo--1__left .zmz-article--comment .zmz-comment__content .zmz-comment__li .comment-item--content {
  padding-left: 55px;
  line-height: 1.5;
  font-size: 14px;
  margin-bottom: 20px;
  color: #929292;
}
.zmz-demo--1__left .zmz-article--comment .zmz-comment__content .zmz-comment__li .comment-item--toolbar {
  padding-left: 55px;
  line-height: 1.5;
  font-size: 14px;
  margin-bottom: 20px;
}
.zmz-demo--1__left .zmz-article--comment .zmz-comment__content .zmz-comment__li .comment-item--quill,
.zmz-demo--1__left .zmz-article--comment .zmz-comment__content .zmz-comment__li .zmz-comment__content {
  border: 1px solid #f4f5f9;
  padding: 15px;
  margin-left: 55px;
}
.zmz-demo--1__left .zmz-article--comment .zmz-article__quill--deitor .ql-toolbar.ql-snow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-color: #F4F5F9;
}
.zmz-demo--1__left .zmz-article--comment .zmz-article__quill--deitor .ql-toolbar.ql-snow .ql-header,
.zmz-demo--1__left .zmz-article--comment .zmz-article__quill--deitor .ql-toolbar.ql-snow .ql-size {
  border-color: #F4F5F9;
}
.zmz-demo--1__left .zmz-article--comment .zmz-article__quill--deitor .ql-container.ql-snow {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: #F4F5F9;
}
.zmz-demo--1__left .zmz-media {
  padding: 20px;
  border: 1px solid #f4f5f9;
}
.zmz-demo--1__left .zmz-media__top {
  display: flex;
}
.zmz-demo--1__left .zmz-media__img {
  width: 100px;
  height: 100px;
  border: 1px solid #fff;
  text-align: center;
  line-height: 100px;
  background: #f7f7f7;
  margin-right: 20px;
}
.zmz-demo--1__left .zmz-media__body {
  flex: 1 1;
}
.zmz-demo--1__left .zmz-media__body-title {
  margin-bottom: 20px;
  font-weight: 700;
  color: #565353;
}
.zmz-demo--1__left .zmz-media__body-content {
  color: #827c7c;
  line-height: 1.5;
}
.zmz-demo--1__left .zmz-media__bottom-right i {
  font-size: 16px;
}
.zmz-demo--1__left .zmz-media__bottom-left,
.zmz-demo--1__left .zmz-media__bottom-right {
  color: #afafaf;
}
.zmz-demo--1__left .zmz-media__bottom-left span,
.zmz-demo--1__left .zmz-media__bottom-right span {
  display: inline-block;
  vertical-align: top;
  padding: 0 5px;
}
.zmz-demo--1__left .zmz-media__bottom-left span i,
.zmz-demo--1__left .zmz-media__bottom-right span i {
  font-size: 20px;
  margin-right: 5px;
  float: left;
}
.zmz-demo--1__left .zmz-media__bottom-left span em,
.zmz-demo--1__left .zmz-media__bottom-right span em {
  display: inline-block;
}
.zmz-demo--1__left .zmz-media__bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  line-height: 38px;
}
.zmz-demo--1__right {
  margin-top: 60px;
}
.zmz-demo--1__right .zmz-right-button {
  text-align: center;
}
.zmz-demo--1__right .zmz-right-button button {
  margin: 0 5px;
  color: #fff;
  background: #d75965;
  border-color: #d75965;
}
.zmz-demo--1__right .zmz-card,
.zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__author {
  margin-bottom: 20px;
}
.zmz-demo--1__right .zmz-card .zmz-card__header .header-title {
  color: #565353;
}
.zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__author .zmz-info--img {
  width: 56px;
  height: 56px;
  border-radius: 7px;
  border: 1px solid #f4f5f9;
  display: inline-block;
  vertical-align: middle;
}
.zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__author .zmz-info--nickname {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
.zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__author .zmz-info--nickname h3 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}
.zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__author .zmz-info--nickname p {
  font-size: 14px;
}
.zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__tab ul {
  box-sizing: content-box;
}
.zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__tab ul li {
  width: 33.33%;
  display: inline-block;
  float: left;
  vertical-align: middle;
}
.zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__tab ul li span {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
}
.zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__tab ul li p {
  text-align: center;
  font-size: 16px;
}
.zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__tab ul:after {
  content: "";
  display: block;
  clear: both;
  overflow: hidden;
}
.zmz-demo--1__right .zmz-card .zmz-help {
  color: #adadad;
  font-size: 14px;
  letter-spacing: 2px;
}
.zmz-demo--1__right .zmz-card .zmz-help span {
  display: flex;
  align-items: center;
  letter-spacing: 0;
}
.zmz-demo--1__right .zmz-card .zmz-help span i {
  font-size: 30px;
  color: #999;
}
.zmz-demo--1__right .zmz-card .zmz-donation__nav span {
  display: inline-block;
  font-size: 14px;
  padding: 5px 10px;
  color: #fff;
  margin: 0 10px;
  border-radius: 5px;
}
.zmz-demo--1__right .zmz-card .zmz-donation__nav span.zmz-zfb {
  background: #00a0e8;
}
.zmz-demo--1__right .zmz-card .zmz-donation__nav span.zmz-wx {
  background: #24aa39;
}
.zmz-demo--1__right .zmz-card .zmz-donation__img {
  margin-top: 20px;
}
.zmz-demo--1__right .zmz-card .zmz-donation__img div {
  display: block;
}
.zmz-demo--1__right .zmz-card .zmz-donation__img div img {
  width: 100%;
}
.zmz-demo--1__section .zmz-section-search {
  margin-top: 15px;
}
.zmz-demo--1__notice .zmz-alert {
  padding: 15px;
}
.zmz-demo--1__notice .zmz-alert i,
.zmz-demo--1__notice .zmz-alert__icon.is-big {
  color: #d75965;
  font-size: 40px;
  width: auto;
}
.zmz-demo--1__notice .zmz-alert__description,
.zmz-demo--1__notice .zmz-alert__title.is-bold {
  color: #d75965;
}
.zmz-demo--1__login {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}
.zmz-demo--1__login .zmz-login__bg {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.3;
}
.zmz-demo--1__login .zmz-login__body {
  padding: 30px;
  background: #fff;
  display: inline-block;
  width: 320px;
  position: relative;
  z-index: 999;
  border-radius: 3px;
  vertical-align: middle;
}
.zmz-demo--1__login .zmz-login__body .zmz-login__disabled {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.zmz-demo--1__login .zmz-login__body .zmz-login__disabled p i {
  font-size: 60px;
  color: #5ea8ff;
}
.zmz-demo--1__login .zmz-login__body .zmz-login__disabled p span {
  display: block;
  text-align: center;
}
.zmz-demo--1__login .zmz-login__body .zmz-login--close {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -10px;
  margin-right: -10px;
  background: #f4f5f9;
  border-radius: 50%;
  padding: 5px;
  z-index: 9;
}
.zmz-demo--1__login .zmz-login__body .zmz-login--close i {
  font-size: 16px;
  color: #ccc;
  font-weight: 400;
}
.zmz-demo--1__login:after {
  content: '';
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}
.zmz-demo--search {
  border: 1px solid #f4f5f9;
  margin: 0 0 30px;
  padding: 10px;
  background: #fff;
}
.zmz-demo--search .zmz-search {
  margin-bottom: 0;
  box-shadow: none;
  box-shadow: initial;
  display: block !important;
}
.zmz-demo--search .zmz-media {
  padding: 10px;
  box-shadow: 0 4px 30px 0 rgba(223, 225, 230, 0.5);
  border: 1px solid #f4f5f9;
}
.zmz-demo--search .zmz-media__top {
  display: flex;
}
.zmz-demo--search .zmz-media__img {
  width: 100px;
  height: 100px;
  border: 1px solid #fff;
  text-align: center;
  line-height: 100px;
  background: #f7f7f7;
  margin-right: 20px;
}
.zmz-demo--search .zmz-media__body {
  flex: 1 1;
}
.zmz-demo--search .zmz-media__body-title {
  margin-bottom: 20px;
  font-weight: 700;
  color: #565353;
}
.zmz-demo--search .zmz-media__body-content {
  color: #827c7c;
  line-height: 1.5;
}
.zmz-demo--search .zmz-media__bottom-right i {
  font-size: 16px;
}
.zmz-demo--search .zmz-media__bottom-left,
.zmz-demo--search .zmz-media__bottom-right {
  color: #afafaf;
}
.zmz-demo--search .zmz-media__bottom-left span,
.zmz-demo--search .zmz-media__bottom-right span {
  display: inline-block;
  vertical-align: top;
  padding: 0 5px;
}
.zmz-demo--search .zmz-media__bottom-left span i,
.zmz-demo--search .zmz-media__bottom-right span i {
  font-size: 20px;
  margin-right: 5px;
  float: left;
}
.zmz-demo--search .zmz-media__bottom-left span em,
.zmz-demo--search .zmz-media__bottom-right span em {
  display: inline-block;
}
.zmz-demo--search .zmz-media__bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  line-height: 38px;
}
.zmz-demo--search .zmz-section .zmz-media {
  box-shadow: none;
  box-shadow: initial;
  border: none;
}
.zmz-demo--release,
.zmz-demo--section {
  box-shadow: 0 4px 30px 0 rgba(223, 225, 230, 0.5);
}
.zmz-demo--search .zmz-section + .zmz-section .zmz-media {
  border-top: 1px solid #f4f5f9;
}
.zmz-demo--search__result {
  padding: 10px;
}
.zmz-demo--release {
  border: 1px solid #f4f5f9;
  padding: 20px;
  margin-bottom: 30px;
}
.zmz-demo--release .zmz-release--breadcrumb {
  padding: 0 20px 40px;
}
.zmz-demo--release .zmz-label__label {
  color: #a09f9f;
}
.zmz-demo--release .zmz-label__input .zmz-input__input {
  border-color: #eaeff6;
}
.zmz-demo--release .zmz-label__input .zmz-tag-button {
  margin-bottom: 10px;
  margin-right: 10px;
}
.zmz-demo--release .zmz-label__input .zmz-tag-button.zmz-active {
  background: #d75965;
  color: #fff;
  border-color: #d75965;
}
.zmz-demo--release .zmz-label__input .zmz-input-tag__tip {
  margin-top: 10px;
  color: #c8c9ce;
}
.zmz-demo--release__quill--deitor .ql-container.ql-snow,
.zmz-demo--release__quill--deitor .ql-toolbar.ql-snow {
  border-color: #eaeff6;
}
.zmz-demo--release__quill--deitor .ql-editor {
  height: 300px;
}
.zmz-demo--release__quill--deitor .ql-snow .ql-stroke {
  stroke: #757575;
}
.zmz-demo--release__title {
  padding: 10px 20px 20px;
  margin-top: 30px;
  font-size: 20px;
  color: #d75965;
  font-weight: 700;
}
.zmz-nav__demo {
  display: inline-block;
  vertical-align: middle;
}
.zmz-nav__demo .nav-item {
  height: 80px;
  color: #b0c4de;
  float: left;
  padding: 0 20px;
  line-height: 80px;
  letter-spacing: 2px;
  position: relative;
  font-weight: 500;
  font-size: 14px;
}
.zmz-nav__demo .nav-item a {
  color: #b0c4de;
  font-weight: 700;
}
.zmz-nav__demo .nav-item a.router-link-exact-active.router-link-active {
  color: #cd303e;
}
.zmz-nav__demo .nav-item:hover {
  color: #cd303e;
  background: #fff;
}
.zmz-nav__demo .nav-item:hover a {
  color: #cd303e;
  font-weight: 700;
}
.zmz-nav__demo .nav-item.is-active,
.zmz-nav__demo .nav-item.is-active a {
  color: #4896ff;
}
.zmz-nav__demo .nav-item.is-disabled {
  cursor: not-allowed;
  font-weight: 500;
  font-size: 14px;
  opacity: 0.4;
}
.zmz-nav__demo .nav-item.is-disabled:hover {
  opacity: 0.4;
  color: #b0c4de;
  background: #fff;
}
.zmz-nav__demo .nav-item.is-active:after {
  content: "";
  display: block;
  overflow: hidden;
  clear: both;
  width: 100%;
  height: 2px;
  background: #4896ff;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
.zmz-nav__login,
.zmz-nav__login .zmz-login--face {
  display: inline-block;
  vertical-align: middle;
}
.zmz-nav__login span {
  margin: 0 10px;
  vertical-align: middle;
  font-size: 14px;
  color: #b0c4de;
  cursor: pointer;
}
.zmz-nav__login span a {
  color: #b0c4de;
}
.zmz-nav__login .zmz-login--face {
  width: 30px;
  height: 30px;
  margin: 0 10px;
  border-radius: 50%;
  border: 1px solid #f4f5f9;
  position: relative;
}
.zmz-nav__login .zmz-login--face span {
  margin: 0;
}
.zmz-nav__login .zmz-login--face img {
  width: 100%;
  height: 100%;
  border: 1px solid #f4f5f9;
  border-radius: 50%;
}
.zmz-nav__login .zmz-login--face ul {
  position: absolute;
  right: 0;
  top: 55px;
  width: 100px;
  border: 1px solid #f4f5f9;
  border-radius: 5px;
  background: #fff;
}
.zmz-nav__login .zmz-login--face ul li {
  line-height: 40px;
  padding: 0 20px;
  font-size: 14px;
}
.zmz__menu--demo .zmz-submenu .submenu-title {
  height: 60px;
  line-height: 60px;
}
.zmz__menu--demo .zmz-submenu .submenu-content .zmz-menu-item,
.zmz__menu--demo .zmz-submenu .submenu-content .zmz-submenu .submenu-title {
  height: 40px;
  line-height: 40px;
}
.zmz-demo--button {
  margin: 20px 20px 20px 0;
}
.zmz-demo--nav {
  height: 100%;
  opacity: 1;
}
.zmz-demo--section {
  background: #fff;
  margin-top: 60px;
}
.zmz-demo--left,
.zmz-demo--right {
  padding: 15px;
  background-color: #fff;
}
@media (max-width: 768px) {
  .zmz-demo--1__header {
    background-color: #fff;
    height: auto;
  }
  .zmz-demo--1__header .zmz-header-container {
    height: auto;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-left a {
    padding: 0 10px;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-button.zmz-demo--button {
    display: block;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-nav.zmz-demo--nav {
    height: 0;
    opacity: 0;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-nav.zmz-demo--nav .zmz-nav__demo {
    width: 100%;
    height: 0;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-nav.zmz-demo--nav .zmz-nav__demo .nav-item {
    float: none;
    display: block;
    height: 0;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-nav.zmz-demo--nav .zmz-nav__login {
    display: block;
    height: 0;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-button.zmz-demo--button {
    display: block;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-nav.zmz-demo--nav {
    height: 250px;
    opacity: 1;
    -webkit-transition: all 1s;
    transition: all 1s;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-nav.zmz-demo--nav .zmz-nav__demo {
    height: auto;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-nav.zmz-demo--nav .zmz-nav__demo .nav-item {
    float: none;
    display: block;
    height: 40px;
    line-height: 40px;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-nav.zmz-demo--nav .zmz-nav__login {
    display: block;
    height: auto;
    padding: 10px;
  }
  .zmz-demo--1__left {
    padding: 0 5px;
    margin-top: 15px;
    background-color: #fff;
  }
  .zmz-demo--1__left .zmz-media__bottom,
  .zmz-demo--1__left .zmz-media__bottom .zmz-media__bottom-left span {
    display: block;
  }
  .zmz-demo--1__left .zmz-article--details .zmz-article__footer .zmz-article__footer__bottom {
    text-align: center;
  }
  .zmz-demo--1__left .zmz-article--details .zmz-article__footer .zmz-article__footer__bottom .footer__bottom__1 {
    display: block;
    margin-right: 0;
    margin-bottom: 20px;
    text-align: right;
  }
  .zmz-demo--1__left .zmz-article--details .zmz-article__footer .zmz-article__footer__bottom .footer__bottom__2 {
    display: block;
  }
  .zmz-demo--1__left .zmz-article--comment {
    padding: 35px 20px;
  }
  .zmz-demo--1__left .zmz-article--comment .quill-deitor__box button {
    text-align: right;
    margin-top: 20px;
  }
  .zmz-demo--1__left .zmz-article--comment .zmz-comment__content .zmz-comment__li .comment-item--quill,
  .zmz-demo--1__left .zmz-article--comment .zmz-comment__content .zmz-comment__li .zmz-comment__content {
    margin-left: 20px;
  }
  .zmz-demo--1__left .zmz-article--search {
    display: block;
  }
  .zmz-demo--1__right {
    padding: 0 5px;
    margin-top: 10px;
    background-color: #fff;
  }
  .zmz-demo--1__right .zmz-right-button {
    padding-top: 20px;
  }
  .zmz-demo--1__right .zmz-search-right {
    display: none;
  }
  .zmz-demo--1__section .zmz-demo--search .pages-item {
    margin-bottom: 10px !important;
  }
  .zmz-demo--1__section .zmz-demo--search .zmz-media__bottom,
  .zmz-demo--1__section .zmz-demo--search .zmz-media__bottom .zmz-media__bottom-left span {
    display: block;
  }
  .zmz-demo--1__section .zmz-demo--release .zmz-release--breadcrumb {
    padding-left: 0;
    padding-right: 0;
  }
  .zmz-demo--1__section .zmz-demo--release .zmz-label__label {
    width: 100%!important;
    text-align: left;
    float: none;
  }
  .zmz-demo--1__section .zmz-demo--release .zmz-label__input {
    width: 100%!important;
    margin-left: 0 !important;
  }
  .zmz-demo--1__section .zmz-demo--release .zmz-from-submit {
    margin-left: 0 !important;
  }
  .zmz-demo--1__login .zmz-login__body {
    padding: 30px;
    background: #fff;
    display: inline-block;
    width: 70%;
    position: relative;
    z-index: 999;
    border-radius: 3px;
    vertical-align: middle;
  }
}
@media (min-width: 768px) {
  .zmz-demo--1__header {
    background-color: #fff;
    height: auto;
  }
  .zmz-demo--1__header .zmz-header-container {
    height: auto;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-left a {
    padding: 0 10px;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-button.zmz-demo--button {
    display: block;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-nav.zmz-demo--nav {
    height: 0;
    opacity: 0;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-nav.zmz-demo--nav .zmz-nav__demo {
    width: 100%;
    height: 0;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-nav.zmz-demo--nav .zmz-nav__demo .nav-item {
    float: none;
    display: block;
    height: 0;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-nav.zmz-demo--nav .zmz-nav__login {
    display: block;
    height: 0;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-button.zmz-demo--button {
    display: block;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-nav.zmz-demo--nav {
    height: 250px;
    opacity: 1;
    -webkit-transition: all 1s;
    transition: all 1s;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-nav.zmz-demo--nav .zmz-nav__demo {
    height: auto;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-nav.zmz-demo--nav .zmz-nav__demo .nav-item {
    float: none;
    display: block;
    height: 40px;
    line-height: 40px;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-nav.zmz-demo--nav .zmz-nav__login {
    display: block;
    height: auto;
    padding: 10px;
  }
  .zmz-demo--1__left,
  .zmz-demo--1__right {
    padding: 0 5px;
    margin-top: 15px;
    background-color: #fff;
  }
  .zmz-demo--1__left .zmz-index-search {
    display: none;
  }
  .zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__author .zmz-info--img {
    margin: 0 auto 20px;
    display: block;
  }
  .zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__author .zmz-info--nickname {
    margin-left: 0;
  }
  .zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__author .zmz-info--nickname h3 {
    text-align: center;
  }
  .zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__tab ul li {
    width: 100%;
  }
  .zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__tab ul li span {
    float: left;
  }
  .zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__tab ul li p {
    float: right;
  }
}
@media (min-width: 992px) {
  .zmz-demo--1__header {
    background-color: #fff;
    height: 80px;
  }
  .zmz-demo--1__header .zmz-header-container {
    height: 80px;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-left a {
    padding: 0 10px;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right {
    float: right;
    clear: none;
    clear: initial;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-button.zmz-demo--button {
    display: none;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-nav.zmz-demo--nav {
    height: auto;
    height: initial;
    opacity: 1;
    opacity: initial;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-nav.zmz-demo--nav .zmz-nav__demo {
    width: auto;
    width: initial;
    height: auto;
    height: initial;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-nav.zmz-demo--nav .zmz-nav__demo .nav-item {
    float: left;
    display: block;
    height: auto;
    height: initial;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right .zmz-nav.zmz-demo--nav .zmz-nav__login {
    display: inline-block;
    height: auto;
    height: initial;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-button.zmz-demo--button {
    display: block;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-nav.zmz-demo--nav {
    height: 250px;
    opacity: 1;
    -webkit-transition: all 1s;
    transition: all 1s;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-nav.zmz-demo--nav .zmz-nav__demo {
    height: auto;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-nav.zmz-demo--nav .zmz-nav__demo .nav-item {
    float: none;
    display: block;
    height: 40px;
    line-height: 40px;
  }
  .zmz-demo--1__header .zmz-header-container .zmz-header-right.zmz-demo--nav__open .zmz-nav.zmz-demo--nav .zmz-nav__login {
    display: block;
    height: auto;
    padding: 10px;
  }
  .zmz-demo--1__left,
  .zmz-demo--1__right {
    padding: 0 5px;
    margin-top: 15px;
    background-color: #fff;
  }
  .zmz-demo--1__left .zmz-index-search {
    display: none;
  }
  .zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__author .zmz-info--img {
    margin: 0;
    margin: initial;
    display: inline-block;
    margin-bottom: 0;
  }
  .zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__author .zmz-info--nickname {
    margin-left: 10px;
  }
  .zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__author .zmz-info--nickname h3 {
    text-align: left;
  }
  .zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__tab ul li {
    width: 33.33%;
  }
  .zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__tab ul li p,
  .zmz-demo--1__right .zmz-card .zmz-userinfo .zmz-info__tab ul li span {
    float: none;
    float: initial;
  }
}
@media (min-width: 1200px) {
  .zmz-button.zmz-demo--button {
    display: none;
  }
  .zmz-nav.zmz-demo--nav {
    display: block;
  }
  .zmz-width-standard {
    width: 1200px;
  }
}
.w-zoom-in-center-enter-active,
.w-zoom-in-center-leave-active {
  -webkit-transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}
.w-zoom-in-center-enter,
.w-zoom-in-center-leave-active {
  opacity: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}
@-webkit-keyframes zmz-spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes zmz-spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes w-opacity {
  0% {
    opacity: 1;
  }
  10%,
  90% {
    opacity: 0.9;
  }
  20%,
  80% {
    opacity: 0.8;
  }
  30%,
  70% {
    opacity: 0.7;
  }
  40%,
  60% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.95;
  }
}
@keyframes w-opacity {
  0% {
    opacity: 1;
  }
  10%,
  90% {
    opacity: 0.9;
  }
  20%,
  80% {
    opacity: 0.8;
  }
  30%,
  70% {
    opacity: 0.7;
  }
  40%,
  60% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.95;
  }
}
.zmz-showmore__text {
  font-size: 13px!important;
  color: #000 !important;
  display: inline-block;
}
.zmz-showmore--block .zmz-showmore__btn,
.zmz-showmore--block .zmz-showmore__text {
  display: block;
}
.zmz-showmore--line .zmz-showmore__btn,
.zmz-showmore--line .zmz-showmore__text,
.zmz-showmore__btn {
  display: inline-block;
}
.zmz-showmore__btn {
  font-size: 13px!important;
  color: #f15c5c !important;
}
.zmz-alert {
  padding: 8px 16px;
  border-radius: 4px;
  position: relative;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  opacity: 1;
  display: flex;
  align-items: center;
}
.zmz-alert.is-center {
  justify-content: center;
}
.zmz-alert--default {
  color: #000;
  background: #fff;
  border: 1px solid #F4F5F9;
}
.zmz-alert--success {
  color: #2eca7b;
  background: #f4fcf8;
}
.zmz-alert--warning {
  color: #d89358;
  background: #fdf9f6;
}
.zmz-alert--confirm {
  color: #53a2f3;
  background: #f6fafe;
}
.zmz-alert--error {
  color: #d85858;
  background: #fef7f7;
}
.zmz-alert--info {
  color: #a0a0a0;
  background: #fafafa;
}
.zmz-alert__icon {
  font-size: 16px;
  width: 16px;
}
.zmz-alert__icon.is-big {
  font-size: 28px;
  width: 28px;
}
.zmz-alert__content {
  padding: 0 8px;
}
.zmz-alert__title {
  font-size: 13px;
  line-height: 18px;
}
.zmz-alert__title.is-bold {
  font-weight: 700;
}
.zmz-alert__closebtn {
  font-size: 12px;
  color: #b4bccc;
  top: 12px;
  right: 15px;
  position: absolute;
  cursor: pointer;
}
.zmz-alert__closebtn.is-customed {
  font-style: normal;
  font-size: 13px;
  top: 9px;
}
.zmz-alert__description {
  font-size: 13px;
  margin: 5px 0 0;
  color: inherit;
}
.zmz-alert-fade-enter,
.zmz-alert-fade-leave-active {
  opacity: 0;
}
.zmz-loadingbar--bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 9999;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  background: #53a2f3;
}
.zmz-loadingbar--error {
  background: #f15c5c;
}
.zmz-loadingbar--peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #53a2f3, 0 0 5px #53a2f3;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0, -4px);
  transform: rotate(3deg) translate(0, -4px);
}
.zmz-loadingbar--spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}
.zmz-loadingbar--spinner .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-top-color: #53a2f3;
  border-left-color: #53a2f3;
  border-radius: 50%;
}
.zmz-loadingbar--spinner .spinner-icon--error {
  border-top-color: #f15c5c;
  border-left-color: #f15c5c;
}
.zmz-skeleton {
  width: 100%;
  height: 0;
  -webkit-animation: w-opacity 2s infinite linear;
  animation: w-opacity 2s infinite linear;
}
.zmz-message--success,
.zmz-message__default {
  background: #cacaca;
  border: 1px solid #cacaca;
}
.zmz-message--warning {
  color: #d89358;
}
.zmz-message--info {
  color: #53a2f3;
  background-color: rgba(83, 162, 243, 0.1);
}
.zmz-message--error {
  color: #d85858;
}
.zmz-message-fade,
.zmz-message-fade-active {
  opacity: 0;
}
.zmz-message-fade-enter-active,
.zmz-message-fade-leave-active {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.zmz-message-fade-enter,
.zmz-message-fade-leave-to {
  opacity: 0;
  -webkit-transform: translateZ(-60px);
  transform: translateZ(-60px);
}
.zmz-message__closeBtn:focus,
.zmz-message__content:focus {
  outline-width: 0;
}
.zmz-message {
  min-width: 380px;
  box-sizing: border-box;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #EBEEF5;
  position: fixed;
  left: 50%;
  top: 20px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #edf2fc;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.4s;
  transition: opacity 0.3s, -webkit-transform 0.4s;
  transition: opacity 0.3s, transform 0.4s;
  transition: opacity 0.3s, transform 0.4s, -webkit-transform 0.4s;
  overflow: hidden;
  padding: 15px 15px 15px 20px;
  display: flex;
  align-items: center;
}
.zmz-message.is-center {
  justify-content: center;
}
.zmz-message.is-closable .zmz-message__content {
  padding-right: 16px;
}
.zmz-message p {
  margin: 0;
}
.zmz-message--info .zmz-message__content {
  color: #909399;
}
.zmz-message--success {
  background-color: #f0f9eb;
  border-color: #e1f3d8;
}
.zmz-message--success .zmz-message__content {
  color: #67C23A;
}
.zmz-message--warning {
  background-color: #fdf6ec;
  border-color: #faecd8;
}
.zmz-message--warning .zmz-message__content {
  color: #E6A23C;
}
.zmz-message--error {
  background-color: #fef0f0;
  border-color: #fde2e2;
}
.zmz-message--error .zmz-message__content {
  color: #F56C6C;
}
.zmz-message__icon {
  margin-right: 10px;
}
.zmz-message__content {
  padding: 0;
  font-size: 14px;
  line-height: 1;
}
.zmz-message__closeBtn {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  color: #C0C4CC;
  font-size: 16px;
}
.zmz-message__closeBtn:hover {
  color: #909399;
}
.zmz-message .zmz-icon-success {
  color: #67C23A;
}
.zmz-message .zmz-icon-error {
  color: #F56C6C;
}
.zmz-message .zmz-icon-info {
  color: #909399;
}
.zmz-message .zmz-icon-warning {
  color: #E6A23C;
}
.zmz-message-fade-enter,
.zmz-message-fade-leave-active {
  opacity: 0;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}
.zmz-card {
  border: 1px solid #f4f5f9;
}
.zmz-card__header {
  padding: 10px 20px;
  border-bottom: #f4f5f9 1px solid;
}
.zmz-card__header .header-title {
  font-size: 16px;
}
.zmz-card__header .header-button {
  border: none;
  float: right;
  font-size: 12px;
  margin-top: 3px;
  color: #f15c5c;
  cursor: pointer;
}
.zmz-card__body {
  padding: 20px;
}
.zmz-card__body .card-item {
  font-size: 14px;
}
.zmz-card__body .card-item + .card-item {
  margin-top: 15px;
}
.zmz-card--always,
.zmz-card--hover:hover {
  box-shadow: 0 4px 30px 0 rgba(223, 225, 230, 0.5);
}
.zmz-card--never {
  box-shadow: none;
}
.zmz-pagination {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.zmz-pagination .zmz-pages {
  text-align: center;
}
.zmz-pagination .zmz-pages .pages-item {
  display: inline-block;
  font-size: 16px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  color: #3f536e;
  margin: 0 5px;
  border-radius: 4px;
}
.zmz-pagination .zmz-pages .pages-item button {
  cursor: no-drop;
  border: 0;
  height: 40px;
  min-width: 40px;
  line-height: 40px;
  display: block;
  background: 0 0;
  font-size: 14px;
  border-radius: 4px;
}
.zmz-pagination .zmz-pages .pages-item:hover {
  color: #53a2f3;
}
.zmz-pagination .zmz-pages .next-page,
.zmz-pagination .zmz-pages .prev-page {
  font-size: 14px;
  color: #3f536e;
}
.zmz-pagination .zmz-pages .active {
  color: #53a2f3;
  cursor: inherit;
}
.zmz-pagination.is-background .zmz-pages .pages-item {
  background: #f7f7f7;
  color: #a0a0a0;
}
.zmz-pagination.is-background .zmz-pages .pages-item button {
  background: #53a2f3;
  color: #f1f1f1;
}
.zmz-pagination.is-background .zmz-pages .pages-item button[disabled=disabled] {
  background: #f7f7f7;
  color: #dedddd;
}
.zmz-pagination.is-background .zmz-pages .pages-item:hover {
  color: #53a2f3;
}
.zmz-pagination.is-background .zmz-pages .pages-item.active {
  background: #53a2f3;
  color: #f1f1f1;
  cursor: inherit;
}
.zmz-pagination__header {
  padding: 10px 20px;
  border-bottom: #f4f5f9 1px solid;
}
.zmz-pagination__header .header-title {
  font-size: 16px;
}
.zmz-pagination__header .header-button {
  border: none;
  float: right;
  font-size: 12px;
  margin-top: 3px;
  color: #53a2f3;
  cursor: pointer;
}
.zmz-pagination__body {
  padding: 20px;
}
.zmz-pagination__body .card-item {
  font-size: 14px;
}
.zmz-pagination__body .card-item + .card-item {
  margin-top: 15px;
}
.zmz-pagination--always,
.zmz-pagination--hover:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.zmz-pagination--never {
  box-shadow: none;
}
.zmz-breadcrumb {
  line-height: 1.2;
  font-size: 14px;
}
.zmz-breadcrumb__item {
  display: inline-block;
}
.zmz-breadcrumb__item:last-child .zmz-breadcrumb__text {
  color: #6f7d90;
}
.zmz-breadcrumb__item:last-child .zmz-breadcrumb__icon {
  display: none;
}
.zmz-breadcrumb__text {
  display: inline-block;
  color: #999;
  font-weight: 700;
}
.zmz-breadcrumb__text a {
  color: #999;
}
.zmz-breadcrumb__icon {
  display: inline-block;
  font-style: normal;
  margin: 0 5px;
  color: #bfbfbf;
  font-weight: 700;
}
.zmz-select__input,
.zmz-select__input--input,
.zmz-select__option {
  color: grey;
  background-color: #fff;
  width: 100%;
  display: block;
  cursor: pointer;
  font-size: 14px;
  box-sizing: border-box;
  outline: 0;
}
.zmz-select {
  position: relative;
}
.zmz-select__input {
  border: 1px solid #eaeff6;
  border-radius: 4px;
}
.zmz-select__input--input {
  border: none;
  padding: 0 15px;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
}
.zmz-select__option {
  box-shadow: 0 10px 60px 0 rgba(29, 29, 31, 0.07);
  position: absolute;
  padding: 10px;
  border: 1px solid #eaeff6;
  border-radius: 4px;
  top: 50px;
}
.zmz-select__option .zmz-option__group .zmz-option__lable span {
  display: block;
  font-size: 14px;
  padding: 5px;
  border-bottom: 1px solid #f4f5f9;
}
.zmz-select__option .zmz-option__horn,
.zmz-select__option .zmz-option__horn:before {
  position: absolute;
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.zmz-select__option .zmz-option__group .zmz-option__list .zmz-option__li {
  padding: 10px 5px;
}
.zmz-select__option .zmz-option__horn {
  top: -7px;
  left: 30px;
  border-bottom: 7px solid #eaeff6;
}
.zmz-select__option .zmz-option__horn:before {
  top: 1px;
  left: 0;
  margin-left: -7px;
  content: "";
  border-bottom: 7px solid #fff;
}
.zmz-switch i {
  width: 40px;
  height: 20px;
  display: inline-block;
  border-radius: 20px;
  background: #d6d6d6;
  vertical-align: middle;
  position: relative;
}
.zmz-switch i:before,
.zmz-switch.is-checked i:before {
  content: "";
  display: inline-block;
  top: 2px;
  width: 16px;
  height: 16px;
}
.zmz-switch i:before {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #fff;
  left: 2px;
  border-radius: 50%;
  position: absolute;
}
.zmz-switch span {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  color: #636363;
}
.zmz-switch.is-checked span {
  color: #f15c5c;
}
.zmz-switch.is-checked i {
  background: #f15c5c;
  border-color: #f15c5c;
  position: relative;
}
.zmz-switch.is-checked i:before {
  position: absolute;
  left: 100%;
  margin-left: -18px;
  right: 2px;
  background: #fff;
}
.zmz-checkbox i:before,
.zmz-checkbox.is-checked i:before {
  content: "";
  top: 50%;
  left: 50%;
  display: inline-block;
}
.zmz-switch__input {
  width: 0;
  height: 0;
  opacity: 0;
}
.zmz-checkbox i {
  width: 14px;
  height: 14px;
  border: 1px solid #d6d6d6;
  display: inline-block;
  border-radius: 4px;
  vertical-align: middle;
}
.zmz-checkbox i:before {
  width: 0;
  height: 0;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.zmz-checkbox span {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  color: #636363;
}
.zmz-checkbox.is-checked span {
  color: #f15c5c;
}
.zmz-checkbox.is-checked i {
  background: #f15c5c;
  border-color: #f15c5c;
  position: relative;
}
.zmz-checkbox.is-checked i:before {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: absolute;
  width: 4px;
  height: 8px;
  border-style: solid;
  border-width: 0 3px 3px 0;
  border-color: #fff;
  -webkit-transform: translate(-50%, -60%) rotate(45deg);
  transform: translate(-50%, -60%) rotate(45deg);
}
.zmz-checkbox__input {
  width: 0;
  height: 0;
  opacity: 0;
}
.zmz-radio {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}
.zmz-radio i {
  width: 14px;
  height: 14px;
  border: 1px solid #d6d6d6;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
}
.zmz-radio i:before,
.zmz-radio.is-checked i:before {
  display: inline-block;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  content: "";
}
.zmz-radio i:before {
  width: 0;
  height: 0;
  -webkit-transition: all 1s;
  transition: all 1s;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
}
.zmz-radio span {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  color: #636363;
}
.zmz-radio.is-checked span {
  color: #f15c5c;
}
.zmz-radio.is-checked i {
  background: #f15c5c;
  border-color: #f15c5c;
  position: relative;
}
.zmz-radio.is-checked i:before {
  width: 4px;
  height: 4px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
}
.zmz-radio__input {
  width: 0;
  height: 0;
  opacity: 0;
}
.zmz-input {
  overflow: hidden;
}
.zmz-input__input {
  cursor: pointer;
  font-size: 14px;
  display: block;
  padding: 0 15px;
  border: 1px solid #eaeff6;
  border-radius: 4px;
  outline: 0;
  color: grey;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
}
.zmz-input__input.is-disabled {
  background: #fafafc;
  color: #F4F5F9;
  cursor: no-drop;
  opacity: 0.5;
}
.zmz-input__input.is-disabled::-webkit-input-placeholder {
  color: #c8c9ce;
}
.zmz-input__input.is-disabled:-moz-placeholder {
  color: #c8c9ce;
}
.zmz-input__input.is-disabled::-moz-placeholder {
  color: #c8c9ce;
}
.zmz-input__input.is-disabled:-ms-input-placeholder {
  color: #c8c9ce;
}
.zmz-input__input.is-hover::-webkit-input-placeholder {
  color: #c8c9ce;
}
.zmz-input__input.is-hover:-moz-placeholder {
  color: #c8c9ce;
}
.zmz-input__input.is-hover::-moz-placeholder {
  color: #c8c9ce;
}
.zmz-input__input.is-hover:-ms-input-placeholder {
  color: #c8c9ce;
}
.zmz-input__input.is-hover:hover {
  border-color: #a0a0a0;
}
.zmz-input__input.is-focus::-webkit-input-placeholder {
  color: #c8c9ce;
}
.zmz-input__input.is-focus:-moz-placeholder {
  color: #c8c9ce;
}
.zmz-input__input.is-focus::-moz-placeholder {
  color: #c8c9ce;
}
.zmz-input__input.is-focus:-ms-input-placeholder {
  color: #c8c9ce;
}
.zmz-input-tag__item i,
.zmz-input__clearbtn i {
  color: #d8d9e0;
  font-size: 24px;
}
.zmz-input__input.is-focus:focus {
  border-color: #53a2f3;
}
.zmz-input--clearbtn {
  position: relative;
}
.zmz-input--clearbtn .zmz-input__input {
  padding: 0 40px 0 10px;
}
.zmz-input__clearbtn {
  position: absolute;
  background: #fff;
  z-index: 9;
  top: 1px;
  right: 1px;
  width: 38px;
  line-height: 38px;
  height: 38px;
  text-align: center;
  cursor: pointer;
}
.zmz-input-tag {
  border: 1px solid #eaeff6;
  border-radius: 4px;
}
.zmz-input-tag:after {
  content: "";
  display: block;
  overflow: hidden;
  clear: both;
}
.zmz-input-tag--disabled {
  background: #fff;
  opacity: 0.5;
  cursor: no-drop;
  border: 1px solid #F4F5F9;
}
.zmz-input-tag--disabled .zmz-input-tag__input {
  cursor: no-drop;
}
.zmz-input-tag__box {
  float: left;
}
.zmz-input-tag__box:after {
  content: "";
  display: block;
  overflow: hidden;
  clear: both;
}
.zmz-input-tag__item {
  float: left;
  line-height: 30px;
  background: #F4F5F9;
  margin: 5px;
  border-radius: 4px;
  position: relative;
}
.zmz-input-tag__item i {
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -15px;
}
.zmz-input-tag__item span {
  padding: 0 50px 0 10px;
  display: inline-block;
  color: grey;
}
.zmz-input-tag__item:after {
  content: "";
  display: block;
  overflow: hidden;
  clear: both;
}
.zmz-input-tag__input {
  float: left;
  cursor: pointer;
  font-size: 14px;
  display: block;
  padding: 0 15px;
  border-radius: 4px;
  outline: 0;
  border: none;
  color: grey;
  height: 40px;
  width: 100%;
  line-height: 40px;
  background-color: #fff;
  box-sizing: border-box;
}
.zmz-textarea {
  position: relative;
  border: 1px solid #eaeff6;
  border-radius: 4px;
}
.zmz-textarea__textarea {
  padding: 5px 7px;
  border: none;
  display: block;
  resize: vertical;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  color: grey;
  outline: 0;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.zmz-datepicker__input__input,
.zmz-label__label {
  font-size: 14px;
  line-height: 40px;
  box-sizing: border-box;
}
.zmz-textarea__textarea::-webkit-input-placeholder {
  color: #c8c9ce;
}
.zmz-textarea__textarea:-moz-placeholder {
  color: #c8c9ce;
}
.zmz-textarea__textarea::-moz-placeholder {
  color: #c8c9ce;
}
.zmz-textarea__textarea:-ms-input-placeholder {
  color: #c8c9ce;
}
.zmz-textarea__textarea:focus {
  border-color: #53a2f3;
}
.zmz-textarea__over {
  border-color: #eaeff6;
}
.zmz-textarea__count {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 14px;
  color: #c8c9ce;
}
.zmz-textarea__num {
  color: #53a2f3;
}
.zmz-textarea__num--over {
  color: #d85858;
}
.zmz-label {
  overflow: hidden;
}
.zmz-label__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  color: #606266;
  padding: 0 12px 0 0;
}
.zmz-label__label.is-disabled {
  background: #F4F5F9;
  color: #F4F5F9;
  cursor: no-drop;
}
.zmz-label__label.is-disabled::-webkit-input-placeholder {
  color: #d0d0d0;
}
.zmz-label__label.is-disabled:-moz-placeholder {
  color: #d0d0d0;
}
.zmz-label__label.is-disabled::-moz-placeholder {
  color: #d0d0d0;
}
.zmz-label__label.is-disabled:-ms-input-placeholder {
  color: #d0d0d0;
}
.zmz-label__label.is-hover::-webkit-input-placeholder {
  color: #d0d0d0;
}
.zmz-label__label.is-hover:-moz-placeholder {
  color: #d0d0d0;
}
.zmz-label__label.is-hover::-moz-placeholder {
  color: #d0d0d0;
}
.zmz-label__label.is-hover:-ms-input-placeholder {
  color: #d0d0d0;
}
.zmz-label__label.is-hover:hover {
  border-color: #a0a0a0;
}
.zmz-label__label.is-focus::-webkit-input-placeholder {
  color: #d0d0d0;
}
.zmz-label__label.is-focus:-moz-placeholder {
  color: #d0d0d0;
}
.zmz-label__label.is-focus::-moz-placeholder {
  color: #d0d0d0;
}
.zmz-label__label.is-focus:-ms-input-placeholder {
  color: #d0d0d0;
}
.zmz-label__label.is-focus:focus {
  border-color: #53a2f3;
}
.zmz-label__input {
  position: relative;
  font-size: 14px;
}
.zmz-formattime {
  display: block;
}
.zmz-datepicker {
  position: relative;
  max-width: 300px;
}
.zmz-datepicker__input__input {
  cursor: pointer;
  display: block;
  padding: 0 38px;
  border: 1px solid #eaeff6;
  border-radius: 4px;
  outline: 0;
  color: grey;
  height: 40px;
  background-color: #fff;
  width: 100%;
}
.zmz-datepicker__input__afterclearbtn,
.zmz-datepicker__input__beforclearbtn {
  z-index: 9;
  top: 1px;
  width: 38px;
  line-height: 38px;
  height: 38px;
  position: absolute;
  text-align: center;
}
.zmz-datepicker__input__input.is-disabled {
  background: #fafafc;
  color: #F4F5F9;
  cursor: no-drop;
  opacity: 0.5;
}
.zmz-datepicker__input__input.is-disabled::-webkit-input-placeholder {
  color: #c8c9ce;
}
.zmz-datepicker__input__input.is-disabled:-moz-placeholder {
  color: #c8c9ce;
}
.zmz-datepicker__input__input.is-disabled::-moz-placeholder {
  color: #c8c9ce;
}
.zmz-datepicker__input__input.is-disabled:-ms-input-placeholder {
  color: #c8c9ce;
}
.zmz-datepicker__input__input.is-hover::-webkit-input-placeholder {
  color: #c8c9ce;
}
.zmz-datepicker__input__input.is-hover:-moz-placeholder {
  color: #c8c9ce;
}
.zmz-datepicker__input__input.is-hover::-moz-placeholder {
  color: #c8c9ce;
}
.zmz-datepicker__input__input.is-hover:-ms-input-placeholder {
  color: #c8c9ce;
}
.zmz-datepicker__input__input.is-hover:hover {
  border-color: #a0a0a0;
}
.zmz-datepicker__input__input.is-focus::-webkit-input-placeholder {
  color: #c8c9ce;
}
.zmz-datepicker__input__input.is-focus:-moz-placeholder {
  color: #c8c9ce;
}
.zmz-datepicker__input__input.is-focus::-moz-placeholder {
  color: #c8c9ce;
}
.zmz-datepicker__input__input.is-focus:-ms-input-placeholder {
  color: #c8c9ce;
}
.zmz-datepicker__input__input.is-focus:focus {
  border-color: #53a2f3;
}
.zmz-datepicker__input__afterclearbtn {
  background: #fff;
  right: 1px;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.zmz-datepicker__input__afterclearbtn i {
  color: #d8d9e0;
  font-size: 24px;
}
.zmz-datepicker__input__beforclearbtn {
  background: #fff;
  left: 1px;
  cursor: pointer;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.zmz-datepicker__input__beforclearbtn i {
  color: #c3c3c3;
  font-size: 24px;
}
.zmz-datepicker__select {
  position: absolute;
  width: 100%;
  padding: 9px;
  top: 60px;
  left: 0;
  box-shadow: 0 2px 15px 0 #eaeff6;
  border: 1px solid #eaeff6;
  background: #fff;
  border-radius: 5px;
}
.zmz-datepicker__select .zmz-datepicker__horn,
.zmz-datepicker__select .zmz-datepicker__horn:before {
  position: absolute;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  display: inline-block;
}
.zmz-datepicker__select .zmz-datepicker__horn {
  top: -7px;
  left: 30px;
  border-bottom: 7px solid #eaeff6;
}
.zmz-datepicker__select .zmz-datepicker__horn:before {
  top: 1px;
  left: 0;
  margin-left: -7px;
  content: "";
  border-bottom: 7px solid #fff;
}
.zmz-datepicker__select .zmz-datepicker__header {
  padding: 10px;
  text-align: center;
}
.zmz-datepicker__select .zmz-datepicker__header div {
  padding: 0 7px;
  font-size: 15px;
  color: grey;
  height: 30px;
  line-height: 30px;
  display: inline-block;
}
.zmz-datepicker__select .zmz-datepicker__header div i {
  font-size: 18px;
}
.zmz-datepicker__select .zmz-datepicker__header .zmz-datepicker__month--prev,
.zmz-datepicker__select .zmz-datepicker__header .zmz-datepicker__year--prev {
  float: left;
}
.zmz-datepicker__select .zmz-datepicker__header .zmz-datepicker__month--next,
.zmz-datepicker__select .zmz-datepicker__header .zmz-datepicker__year--next {
  float: right;
}
.zmz-datepicker__select .zmz-datepicker__body .zmz-datepicker__weeks {
  border-bottom: 1px solid #f4f5f9;
}
.zmz-datepicker__select .zmz-datepicker__body .zmz-datepicker__weeks .zmz-datepicker__weeks--single {
  width: 14.28%;
  display: inline-block;
  text-align: center;
}
.zmz-datepicker__select .zmz-datepicker__body .zmz-datepicker__weeks .zmz-datepicker__weeks--single span {
  width: 30px;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  font-size: 16px;
  color: grey;
}
.zmz-datepicker__select .zmz-datepicker__body .zmz-datepicker__days {
  padding: 5px 0;
}
.zmz-datepicker__select .zmz-datepicker__body .zmz-datepicker__days .zmz-datepicker__days--rows {
  width: 100%;
  padding: 5px 0;
}
.zmz-datepicker__select .zmz-datepicker__body .zmz-datepicker__days .zmz-datepicker__days--rows .days--single {
  width: 14.28%;
  display: inline-block;
  text-align: center;
}
.zmz-datepicker__select .zmz-datepicker__body .zmz-datepicker__days .zmz-datepicker__days--rows .days--single span {
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
  margin: 0 auto;
  font-size: 14px;
  color: grey;
}
.zmz-datepicker__select .zmz-datepicker__body .zmz-datepicker__days .zmz-datepicker__days--rows .days--single span.days--active {
  color: #53a2f3;
}
.zmz-datepicker__select .zmz-datepicker__body .zmz-datepicker__days .zmz-datepicker__days--rows .days--single span.days--ismonth {
  color: #ced0d6;
}
.zmz-table__thead,
.zmz-table__thead thead tr {
  background: #fff;
}
.zmz-table__thead thead tr th {
  padding: 15px 0;
  color: #707580;
  border-bottom: 1px solid #f2f2f3;
  text-align: left;
}
.zmz-table__thead thead tr th span {
  display: block;
  padding: 0 15px;
  font-size: 16px;
}
.zmz-table__tbody,
.zmz-table__tbody tbody tr {
  background: #fff;
}
.zmz-table__tbody tbody tr td {
  padding: 15px 0;
  color: #707580;
  border-bottom: 1px solid #f2f2f3;
}
.zmz-table__tbody tbody tr td .zmz-table__tbody_td {
  display: block;
  padding: 0 15px;
  font-size: 14px;
}
.zmz-progress {
  width: 320px;
  padding-right: 50px;
  position: relative;
}
.zmz-progress__baseline {
  background: #f4f5f9;
  width: 320px;
  height: 6px;
  border-radius: 6px;
}
.zmz-progress__speed {
  background: #F15C5C;
  height: 6px;
  width: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 6px;
}
.zmz-dropdown__list,
.zmz-dropdown__title {
  outline: 0;
  background-color: #fff;
  box-sizing: border-box;
  cursor: pointer;
}
.zmz-progress__text {
  position: absolute;
  right: 0;
  top: 50%;
  color: #6e7f86;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.zmz-dropdown {
  position: relative;
}
.zmz-dropdown__title {
  font-size: 14px;
  display: inline-block;
  border-radius: 4px;
  color: grey;
  line-height: normal;
  line-height: initial;
  padding: 10px 0;
}
.zmz-dropdown__title span {
  vertical-align: middle;
  display: inline-block;
}
.zmz-dropdown__title i {
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
}
.zmz-dropdown__list {
  box-shadow: 0 10px 60px 0 rgba(29, 29, 31, 0.07);
  position: absolute;
  font-size: 14px;
  display: block;
  border: 1px solid #eaeff6;
  border-radius: 4px;
  top: inherit;
  color: grey;
}
.zmz-dropdown__list .zmz-dropdown__horn,
.zmz-dropdown__list .zmz-dropdown__horn:before {
  position: absolute;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.zmz-dropdown__list .zmz-dropdown__item {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  white-space: pre;
}
.zmz-dropdown__list .zmz-dropdown__item a {
  color: grey;
  display: block;
  text-decoration: none;
}
.zmz-dropdown__list .zmz-dropdown__item:hover {
  background: #f4f5f9;
}
.zmz-dropdown__list .zmz-dropdown__horn {
  top: -7px;
  left: 20px;
  display: inline-block;
  border-bottom: 7px solid #eaeff6;
}
.zmz-dropdown__list .zmz-dropdown__horn:before {
  top: 1px;
  left: 0;
  margin-left: -7px;
  content: "";
  display: inline-block;
  border-bottom: 7px solid #fff;
}
.zmz-tab__nav {
  position: relative;
  height: 42px;
  box-sizing: border-box;
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 15px;
}
.zmz-tab__nav--line {
  margin-top: -1px;
  overflow: hidden;
}
.zmz-tab__nav--line .zmz-tab__nav--item {
  display: inline-block;
  padding: 0 15px;
  height: 40px;
  line-height: 40px;
  color: #516871;
  border: 1px solid transparent;
  cursor: pointer;
}
.zmz-tab__nav--line .zmz-tab__nav--item.is-active {
  border: 1px solid #d6d6d6;
  border-bottom: 1px solid #fff;
  border-radius: 4px 4px 0 0;
}
