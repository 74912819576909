article,
aside,
blockquote,
body,
button,
code,
dd,
details,
div,
dl,
dt,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
input,
legend,
li,
menu,
nav,
ol,
p,
pre,
section,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
}
img {
  display: inline-block;
  border: 0;
  vertical-align: middle;
}
ul,
li {
  list-style-type: none;
}
a {
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: Consolas,Menlo,Courier,monospace;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.zmz-mt-10 {
  margin-top: 10px;
}
.zmz-mt-20 {
  margin-top: 20px;
}
.zmz-mb-20 {
  margin-bottom: 20px;
}
.zmz-mr-10 {
  margin-right: 10px;
}
.zmz-ml-10 {
  margin-left: 10px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.zmz-fs-12 {
  font-size: 12px;
}
.zmz-fs-14 {
  font-size: 14px;
}
.zmz-fs-16 {
  font-size: 16px;
}
.zmz-fs-18 {
  font-size: 18px;
}
.zmz-fs-20 {
  font-size: 20px;
}
.zmz-fs-22 {
  font-size: 22px;
}
.zmz-fs-24 {
  font-size: 24px;
}
.zmz-rt {
  float: right;
}
.zmz-lt {
  float: left;
}
.zmz-ar::after {
  content: "";
  clear: both;
  display: block;
  overflow: hidden;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.zmz-react-header {
  background-color: #fff;
  box-shadow: 0 10px 60px 0 rgba(29, 29, 31, 0.07);
  opacity: 0.98;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  transition: all 0.3s;
  z-index: 100;
}
.zmz-react-header .zmz-react-header__box {
  height: 80px;
  padding: 0 20px;
}
.zmz-react-header .zmz-react-header__box .zmz-react-header__left {
  float: left;
}
.zmz-react-header .zmz-react-header__box .zmz-react-header__left a {
  display: inline-block;
  vertical-align: middle;
}
.zmz-react-header .zmz-react-header__box .zmz-react-header__left a img {
  width: 80px;
  height: 80px;
  display: inline-block;
}
.zmz-react-header .zmz-react-header__box .zmz-react-header__left a img.zmz-react-logo {
  animation: App-logo-spin infinite 20s linear;
  pointer-events: none;
}
.zmz-react-header .zmz-react-header__box .zmz-react-header__right {
  float: right;
}
.zmz-react-header .zmz-react-header__box .zmz-react-header__right .zmz-react-button {
  display: none;
}
.zmz-react-header .zmz-react-header__box .zmz-react-header__right .zmz-react-nav .zmz-nav__demo {
  height: 80px;
}
.zmz-react-header .zmz-react-header__box .zmz-react-header__right .zmz-react-nav .zmz-nav__demo .nav-item {
  height: 80px;
  color: #b0c4de;
  float: left;
  padding: 0 20px;
  line-height: 80px;
  letter-spacing: 2px;
  position: relative;
  font-weight: 500;
  font-size: 14px;
}
.zmz-react-header .zmz-react-header__box .zmz-react-header__right .zmz-react-nav .zmz-nav__demo .nav-item a {
  color: #b0c4de;
  font-weight: 700;
  transition: all 0.5s;
}
.zmz-react-header .zmz-react-header__box .zmz-react-header__right .zmz-react-nav .zmz-nav__demo .nav-item a:hover {
  color: #cd303e;
  background: #fff;
}
.zmz-react-section {
  width: 1200px;
  margin: 0 auto;
}
.zmz-react-pt80 {
  padding-top: 80px;
}
.zmz-react-json {
  margin-top: 30px;
  background: #fff;
  padding: 20px;
  box-shadow: 0 10px 60px 0 rgba(29, 29, 31, 0.07);
}
.zmz-react-json .zmz-react-json__button {
  margin-top: 20px;
}
.zmz-react-json .zmz-react-json__button button {
  margin: 0 15px;
}
.item {
  border: 1px solid #66dbfb;
  width: 90%;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 #b3b3b3;
  margin: 5px auto;
  background: #fff;
}
.item.active {
  border-style: dashed;
}
.item-header {
  font-size: 12px;
  color: #9e9e9e;
  padding: 3px 5px;
}
.item-main {
  padding: 5px;
  font-size: 14px;
  color: #424242;
  height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.item-header-point {
  background: #ccc;
  float: right;
  padding: 0 4px;
  min-width: 10px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
}
.col {
  border: 1px solid #f9f9f9;
  width: 180px;
  height: 100%;
  margin: 0 2px;
  background: #eee;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.col-header {
  height: 40px;
  line-height: 40px;
  background: #66dbfb;
  color: #fff;
  text-align: center;
}
.col-main {
  overflow: auto;
  flex-grow: 1;
  background: #f4f5f9;
}
.col-main.active {
  background: #00ad23;
  opacity: 0.1;
}
.task-wrapper {
  display: flex;
  height: 700px;
  width: 1200px;
  margin-top: 30px;
}
@keyframes myFrist {
  0% {
    background: url(../img/1-1.png);
    background-size: 100% 100%;
    transition: all 1s;
  }
  25% {
    background: url(../img/1-2.png);
    background-size: 100% 100%;
    transition: all 1s;
  }
  50% {
    background: url(../img/1-3.png);
    background-size: 100% 100%;
    transition: all 1s;
  }
  75% {
    background: url(../img/1-4.png);
    background-size: 100% 100%;
    transition: all 1s;
  }
  100% {
    background: url(../img/1-5.png);
    background-size: 100% 100%;
    transition: all 1s;
  }
}
